export const questionnaire = {
  questionnaire_list_title: 'Questionnaires List',
  questionnaire_list_createButton: 'New Questionnaire',
  questionnaire_list_create_title: 'Create Questionnaire',
  questionnaire_list_create_subtitle: 'Enter questionnaire information',
  questionnaire_searchPlaceholder: 'Search by title',
  questionnaire_numberDisplayed: '{{number}} Results',
  questionnaire_card_questions: 'Questions',
  questionnaire_card_button: 'Modify',
  questionnaire_card_deleteConfirm_title: 'Delete questionnaire',
  questionnaire_card_deleteConfirm_text: 'Delete this questionnaire? This action can\'t be undone',
  questionnaire_details_title: 'Questionnaire Details',
  questionnaire_details_goBack: 'Go back to questionnaires',
  questionnaire_details_tab_config: 'Configuration',
  questionnaire_details_tab_trads: 'Translations',
  questionnaire_details_questions_createButton: 'New question',
  questionnaire_question_create_title: 'Create question',
  questionnaire_question_create_subtitle: 'Please fill the form below to initialize a new question',
  questionnaire_translation_header_original: 'Original translation',
  questionnaire_translation_header_translation: 'Translation',
  questionnaire_translation_header_valid: 'Is valid ?',
  questionnaire_translation_questionnaireTitle: 'Questionnaire Title',
  questionnaire_translation_questionnaireDescription: 'Questionnaire Description',
  questionnaire_translation_questions: 'Questions Translation',
  questionnaire_translation_title: 'Questionnaire Translations',
  questionnaire_translation_goBack: 'Go back to translations',
  questionnaire_update_error: 'questionnaire update has failed',
  questionnaire_update_success: 'questionnaire update has succeeded',
  questionnaire_loadList_error: 'Error while loading questionnaire list',
  questionnaire_load_error: 'Error while loading questionnaire',
  questionnaire_delete_error: 'Error while deleting questionnaire',
  questionnaire_delete_success: 'The questionnaire has been correctly deleted',
  questionnaire_create_success: 'New questionnaire successfully created',
  questionnaire_create_error: 'Error while creating the questionnaire',
  questionnaire_role_delete_success: 'Role removed',
  questionnaire_role_delete_error: 'Error while removing the role',
  questionnaire_role_post_success: 'Role added',
  questionnaire_role_post_error: 'Error while adding the role',
  questionnaire_Standardized: 'Standardized questionnaires',
  questionnaire_Study: 'Study questionnaires',
  questionnaire_questions_deleteConfirm_title: 'Delete question',
  questionnaire_questions_deleteConfirm_text: 'Are you sure to delete this question? This action cannot be undone'
};
