export const study = {
  study_config_multilang_title: 'Langues de translation',
  study_config_multilang_text: 'Veuillez sélectionner les langues additionnelles de l\'étude',
  study_config_lang_title: 'Langue de l\'étude',
  study_config_lang_text: 'Veuillez sélectionner la langue principale de l\'étude',
  study_config_multilang_creation_error: 'Erreur lors de la création de la langue de l\'étude',
  study_config_multilang_creation_success: 'Langue de l\'étude créée avec succès',
  study_config_multilang_delete_error: 'Erreur lors de la suppression de la langue de l\'étude',
  study_config_multilang_delete_success: 'Langue de l\'étude supprimée avec succès',
  study_config_multilang_load_error: 'Erreur lors du chargement des langues de l\'étude',
  study_config_lang_load_error: 'Erreur lors du chargement des langues',
};
