import { ReactElement, useState, useRef } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FileUploadIcon from '@material-ui/icons/FileUpload';

import { useTranslation } from 'react-i18next';
import DownloadButton from '../../atoms/DownloadButton';

interface UploadFileProps {
  title: string;
  text: string;
  button_label: string;
  onClick: (file :File) => void;
  endpoint?: string;
}

const titleId = 'action-confirm-title';
const descriptionId = 'action-confirm-description';

const UploadFile = ({ title = 'file upload', button_label = 'invitation_user_create_bulk', text = 'upload your csv', endpoint = 'users/current/invitations/template', onClick }: UploadFileProps): ReactElement => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const fileInputRef: any = useRef(null);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        type="button"
        variant="text"
        className="px-6 mx-6"
      >
        <Box className="flex items-center">
          <AddIcon
            fontSize="small"
            color="inherit"
          />
          <span className="pl-2">{t(button_label)}</span>
        </Box>
      </Button>
      <input
        ref={fileInputRef}
        id="file-upload"
        type="file"
        accept=".csv"
        value=""
        style={{ display: 'none' }}
        onChange={(e) => {
          onClick(e.target.files?.[0]);
          setOpen(false);
        }}
      />
      <Dialog
        open={open}
        onClose={(): void => setOpen(false)}
        aria-labelledby={titleId}
        aria-describedby={descriptionId}
        PaperProps={{ style: { backgroundColor: 'white' } }}
      >
        <DialogTitle id={titleId} className="text-primary">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id={descriptionId} className="text-dark">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DownloadButton endpoint={endpoint} />
          <Button
            onClick={(): void => {
              fileInputRef.current.click();
            }}
            color="primary"
            variant="outlined"
          >
            {t('invitation_user_upload') }
            <FileUploadIcon
              fontSize="small"
              color="inherit"
            />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadFile;
