import { lazy, Suspense } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './components/UI/organisms/AuthGuard';
import DiapasonLayout from './components/UI/organisms/DiapasonLayout/DiapasonLayout';
import GuestGuard from './components/UI/organisms/GuestGuard';
import LoadingScreen from './components/UI/organisms/LoadingScreen';
import StudyGuard from './components/UI/organisms/StudyGuard';
import RandomizationSlots from './components/UI/pages/Randomization/RandomizationSlots';
import DiapasonContentWrapper from './components/UI/templates/DiapasonContentWrapper';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Diapason pages
const Login = Loadable(lazy(() => import('./components/UI/pages/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./components/UI/pages/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./components/UI/pages/PasswordReset')));
const HomePage = Loadable(lazy(() => import('./components/UI/pages/HomePage/HomePage')));
const Deployement = Loadable(lazy(() => import('./components/UI/pages/Deployement')));
const DataExport = Loadable(lazy(() => import('./components/UI/pages/DataExport')));
const SequenceDescription = Loadable(lazy(() => import('./components/UI/pages/Sequences/SequenceDescription')));
const FinalParams = Loadable(lazy(() => import('./components/UI/pages/FinalParams')));
const RandomizationDescription = Loadable(lazy(() => import('./components/UI/pages/Randomization/RandomizationDescription')));
const ProfilesDescription = Loadable(lazy(() => import('./components/UI/pages/Profile/profileDescription')));
const DiapasonHome = Loadable(lazy(() => import('./components/UI/pages/DiapasonHome/DiapasonHome')));
const Questionnaires = Loadable(lazy(() => import('./components/UI/pages/Questionnaires/Questionnaires')));
const QuestionnaireDetails = Loadable(lazy(() => import('./components/UI/pages/QuestionnaireDetails/QuestionnaireDetails')));
const QuestionDetails = Loadable(lazy(() => import('./components/UI/pages/QuestionDetails/QuestionDetails')));
const IntervenorsDefinition = Loadable(lazy(() => import('./components/UI/pages/RoleDefinition/IntervenorsDefinition')));
const SubjectsDefinition = Loadable(lazy(() => import('./components/UI/pages/RoleDefinition/SubjectsDefinition')));
const InvestigatorsDefinition = Loadable(lazy(() => import('./components/UI/pages/RoleDefinition/InvestigatorsDefinition')));
const ContentDetails = Loadable(lazy(() => import('./components/UI/pages/ContentDetails/ContentDetails')));
const ContentsDescription = Loadable(lazy(() => import('./components/UI/pages/Contents/ContentsDescription')));
const Contents = Loadable(lazy(() => import('./components/UI/pages/Contents/Contents')));
const Sites = Loadable(lazy(() => import('./components/UI/pages/Sites/Sites')));
const Invitations = Loadable(lazy(() => import('./components/UI/pages/Invitations/Invitations')));
const InvitationDetails = Loadable(lazy(() => import('./components/UI/pages/InvitationDetails/InvitationDetails')));
const InvitationCreate = Loadable(lazy(() => import('./components/UI/pages/InvitationCreate/InvitationCreate')));
const InvitationUser = Loadable(lazy(() => import('./components/UI/pages/InvitationUser.ts/InvitationUser')));
const Sequences = Loadable(lazy(() => import('./components/UI/pages/Sequences/Sequences')));
const SequenceDetails = Loadable(lazy(() => import('./components/UI/pages/SequenceDetails/SequenceDetails')));
const ReminderDetails = Loadable(lazy(() => import('./components/UI/pages/ReminderDetails/ReminderDetails')));
const ReminderCreate = Loadable(lazy(() => import('./components/UI/pages/ReminderCreate/ReminderCreate')));
const StudyTranslations = Loadable(lazy(() => import('./components/UI/pages/StudyTranslations/StudyTranslations')));
const QuestionnaireTranslations = Loadable(lazy(() => import('./components/UI/pages/TranslationDetails/QuestionnaireTranslations')));
const RoleTranslations = Loadable(lazy(() => import('./components/UI/pages/TranslationDetails/RoleTranslations')));
const ContentsTranslations = Loadable(lazy(() => import('./components/UI/pages/TranslationDetails/ContentsTranslations')));
const ProfileUpdate = Loadable(lazy(() => import('./components/UI/pages/Profile/ProfileUpdate')));
const SequenceTranslations = Loadable(lazy(() => import('./components/UI/pages/TranslationDetails/SequenceTranslations')));
const Randomization = Loadable(lazy(() => import('./components/UI/pages/Randomization/Randomization')));
const InvitationTranslations = Loadable(lazy(() => import('./components/UI/pages/TranslationDetails/InvitationTranslations')));
const StratificationDetails = Loadable(lazy(() => import('./components/UI/pages/StratificationDetails/StratificationDetails')));
const MassCommunication = Loadable(lazy(() => import('./components/UI/pages/MassCommunication')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: (
          <GuestGuard>
            <PasswordRecovery />
          </GuestGuard>
        )
      },
      {
        path: 'password-reset',
        element: (
          <GuestGuard>
            <PasswordReset />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <StudyGuard>
          <DiapasonLayout />
        </StudyGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: '/questionnaires',
        element: <Questionnaires />
      },
      {
        path: '/questionnaires/:questionnaireId/details',
        element: <QuestionnaireDetails />
      },
      {
        path: '/questionnaires/:questionnaireId/question/:questionId/details',
        element: <QuestionDetails />
      },
      {
        path: '/definition/intervenors',
        element: <IntervenorsDefinition />
      },
      {
        path: '/definition/subjects',
        element: <SubjectsDefinition />
      },
      {
        path: '/definition/investigators',
        element: <InvestigatorsDefinition />
      },
      {
        path: '/translations',
        element: <StudyTranslations />
      },
      {
        path: '/translations/:lang/questionnaires/:questionnaireId',
        element: <QuestionnaireTranslations />
      },
      {
        path: '/translations/:lang/roles',
        element: <RoleTranslations />
      },
      {
        path: '/translations/:lang/contents/:contentsId',
        element: <ContentsTranslations />
      },
      {
        path: '/translations/:lang/sequences',
        element: <SequenceTranslations />
      },
      {
        path: '/translations/:lang/invitations',
        element: <InvitationTranslations />
      },
      {
        path: '/sites',
        element: <Sites />
      },
      {
        path: '/contents/description',
        element: <ContentsDescription />
      },
      {
        path: '/contentType/study-presentations',
        element: <Contents />
      },
      {
        path: '/contentType/consent',
        element: <Contents />
      },
      {
        path: '/contentType/sequence-content',
        element: <Contents />
      },
      {
        path: '/final-params',
        element: <FinalParams />
      },
      {
        path: '/contentType/study-presentations/:contentsId',
        element: <ContentDetails />
      },
      {
        path: '/contentType/sequence-content/:contentsId',
        element: <ContentDetails />
      },
      {
        path: '/contentType/consent/:contentsId',
        element: <ContentDetails />
      },
      {
        path: 'invitations',
        element: <Invitations />
      },
      {
        path: 'invitation-investigators',
        element: <InvitationUser />
      },
      {
        path: 'invitations/:invitationId',
        element: <InvitationDetails />
      },
      {
        path: 'invitations/new',
        element: <InvitationCreate />
      },
      {
        path: 'mass-communication',
        element: <MassCommunication />
      },
      {
        path: '/sequences/description',
        element: <SequenceDescription />
      },
      {
        path: '/sequences',
        element: <Sequences />
      },
      {
        path: '/sequences/:sequenceId',
        element: <SequenceDetails />
      },
      {
        path: '/sequences/:sequenceId/reminders/:reminderId',
        element: <ReminderDetails />
      },
      {
        path: '/sequences/:sequenceId/reminders/new',
        element: <ReminderCreate />
      },
      {
        path: '/account',
        element: <ProfileUpdate />
      },
      {
        path: '/deployment',
        element: <Deployement />
      },
      {
        path: '/randomization/settings',
        element: <Randomization />
      },
      {
        path: '/randomization_description',
        element: <RandomizationDescription />
      },
      {
        path: '/randomization/slots',
        element: <RandomizationSlots />
      },
      {
        path: '/randomization/:roleId/stratifications/:stratificationId',
        element: <StratificationDetails />
      },
      {
        path: '/dashboard',
        element: <DiapasonHome />
      },
      {
        path: '/home-page',
        element: <HomePage />
      },
      {
        path: '/exports',
        element: <DataExport />
      },
      {
        path: '/profiles/description',
        element: <ProfilesDescription />
      },
    ].map(({ path, element }) => ({ path, element: <DiapasonContentWrapper>{element}</DiapasonContentWrapper> }))
  }
];

export default routes;
