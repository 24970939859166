export const home = {
  home_title: 'Accueil',
  graph_pco1_active_legend: 'Active (Commencé à répondre)',
  graph_pco1_title: 'Suivi des inclusions',
  graph_poc1_subtitle: 'statut du sujet par étude',
  graph_poc1_legend_title: 'Légende',
  graph_poc1_xaxis: 'Date d\'inclusion',
  graph_poc1_yaxis: 'Nombre',
  graph_poc2_xaxis: 'Date d\'inclusion',
  graph_poc2_yaxis: 'Nombre',
  graph_poc2_title: 'Nombre de sujets actifs par séquence',
  graph_poc2_subtitle: 'suivi des patients par séquences',
  graph_poc2_legend_title: 'Légende',
  graph_poc3_title: 'Nombre de patients par statut de la séquence 1',
  home_coding_title: 'Pas besoin de code',
  home_coding_content: 'Créez votre application accessible sans codage, grâce à un environnement facile à utiliser.',
  home_questionnaire_title: 'Questionnaire',
  home_questionnaire_content: 'Créez des questionnaires avec plusieurs types de questions. Personnalisez votre étude avec des graphiques, des images et de la typographie, de la vidéo.',
  home_invitation_title: 'Invitation & Reminder',
  home_invitation_content: 'Invitez vos utilisateurs par e-mail, SMS ou lien public, et rappelez-leur de participer à l\'étude avec des notifications mobiles.',
  graph_pco5_title: 'Suivi des patients dans l\'étude',
  graph_poc5_subtitle: 'statut de séquences',
  graph_pco4_title: 'Suivi du recrutement',
  graph_poc4_subtitle: 'nombre de sujet par étude'
};
