export const InvitationUsers = {
  invitation_users_recipient_name: 'First name',
  invitation_users_recipient_prename: 'Last name',
  invitation_users_recipient_canal: 'Channel',
  invitation_users_recipient_email: 'Email',
  invitation_users_recipient_phone_number: 'Phone number',
  invitation_users_recipient_lang: 'Language',
  invitation_users_recipient_lang_en: 'English',
  invitation_users_recipient_lang_fr: 'French',
  invitation_users_recipient_contact_information: 'Contact information',
  invitation_users_status: 'Status',
  invitation_users_date: 'Invitation date',
  invitation_user_create_title: 'Invite an Investigator',
  invitation_user_create_subtitle: 'Create an invitation for an investigator',
  PENDING_UNIQUE: 'Invitation sent',
  invitation_user_create_bulk: 'Invite multiple investigators',
  invitation_user_create_bulk_subtitle: 'Create multiple invitations for investigators',
  invitation_user_create_bulk_description: 'You can invite multiple investigators at once by uploading a CSV file. Download the template that you can fill in and upload it here.',
  invitation_user_upload: 'Upload your CSV file',
  invitation_user_download_template: 'Download CSV template',
  invitation_user_load_success: 'Invitations loaded',
  invitation_user_load_error: 'Error loading invitations',
  langs_load_error: 'Error loading languages',
  invitation_user_load_create_success: 'Invitation created',
  invitation_user_load_create_error: 'Error creating invitation',
  invitations_user_bulk_create_success: 'Invitations created',
  invitations_user_bulk_create_error: 'Error creating invitations',
};
