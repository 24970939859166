export const sites = {
  sites_title: 'Gérer les sites',
  sites_list_title: 'Liste des sites',
  sites_createButton: 'Créer un site',
  sites_form_submit: 'Créer le site',
  sites_form_edit: 'Éditer le site',
  sites_search_placeholder: 'Rechercher par label',
  sites_address: 'Adresse',
  sites_subSite: 'Sous-sites',
  sites_details_titleCreate: 'Créer un site',
  sites_details_titleEdit: 'Éditer un site',
  sites_details_subtitle: 'Saisissez les informations du site',
  sites_details_goBack: 'Retour à la liste des sites',
  sites_details_subSite_create: 'Créer un sous-site',
  sites_details_subSite_list: 'Liste des sous-sites',
  sites_details_noSubsites: 'Aucun sous-site',
  sites_deleteConfirm_title: 'Supprimer un site',
  sites_deleteConfirm_text: 'Êtes-vous sur de vouloir supprimer ce site ? Cette action ne peut pas être annulée',
  sites_details_subsite_deleteConfirm_title: 'Supprimer un sous-site',
  sites_details_subsite_deleteConfirm_text: 'Êtes-vous sur de vouloir supprimer ce sous-site ? Cette action ne peut pas être annulée',
  sites_loadList_error: 'Erreur lors de la récupération des sites',
  sites_create_error: 'Erreur lors de la création du site',
  sites_create_success: 'Nouveau site correctement créé',
  sites_delete_error: 'Erreur lors de la suppression du site',
  sites_delete_success: 'Le site a bien été supprimé',
  sites_patch_error: 'Erreur lors de la mise à jour du site',
  sites_patch_success: 'Site correctement mis à jour',
  sites_subSite_create_error: 'Erreur lors de la création du sous-site',
  sites_subSite_create_success: 'Sous-site correctement créé',
  sites_subSite_delete_error: 'Erreur lors de la suppression du sous-site',
  sites_subSite_delete_success: 'Sous-site correctement supprimé',
  sites_subSite_patch_error: 'Erreur lors de la mise à jour du sous-site',
  sites_subSite_patch_success: 'Sous-site correctement mis à jour',
};
