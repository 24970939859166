import { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface WelcomeTitleProps {
  skipLine?: boolean
  endPonctuation?: string
  className?: string
  isDark?: boolean
}

const WelcomeTitle = ({ skipLine = false, endPonctuation = '!', isDark = false, className = '' }: WelcomeTitleProps) => {
  const { t } = useTranslation();

  return (
    <span className={`font-bold text-5xl pb-12 ${className}`}>
      <span className={`pr-2 ${isDark ? 'text-dark' : 'text-white'}`}>{t('login_welcome_to')}</span>
      {skipLine ? <br /> : null}
      <span className="text-primary">
        KASTAFIORE
        <span className="text-white pl-2">{endPonctuation}</span>
      </span>
    </span>
  );
};

export default memo(WelcomeTitle);
