export const stratification = {
  stratification_details_title: 'Détails d\'une stratification',
  stratification_details_answers_button: 'Créer réponse',
  stratification_details_answers_title: 'Réponses',
  stratification_details_answer_edit: 'Éditer réponse',
  stratification_details_answer_create: 'Créer réponse',
  stratification_details_deleteConfirm_title: 'Supprimer réponse',
  stratification_details_deleteConfirm_text: 'Supprimer cette réponse ? Cette action ne peut être annulée',
  stratification_details_load_error: 'Erreur lors du chargement des détails de la stratification',
  stratification_details_patch_error: 'Erreur lors de la mise à jour des détails de la stratification',
  stratification_details_patch_success: 'Stratification correctement mise à jour',
  stratification_details_delete_answer_success: 'La réponse a été supprimée avec succès',
  stratification_details_delete_answer_error: 'Erreur lors de la suppression de la réponse',
  stratification_details_post_answer_success: 'Réponse correctement créée',
  stratification_details_post_answer_error: 'Erreur lors de la création de la réponse',
  stratification_details_patch_answer_success: 'Réponse correctement mise à jour',
  stratification_details_patch_answer_error: 'Erreur lors de la mise à jour de la réponse',
  stratification_details_reorder_answer_error: 'Erreur lors de la réorganisation des réponses',
};
