import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteAllRandomisationSlots, getRandomisationSlots, postBulkRandomisationSlots } from 'src/service/randomizationApi';
import { getSubjectsConfig } from 'src/service/studyRoles';
import { exportFile } from '../InvitationUser.ts/useInvitationUserApi';

interface RandomizationSlotsApiHookReturn {
  slots: any;
  loadSlots: () => Promise<void>;
  loadingImport: boolean;
  endpoint: string;
  postRandomizationArmSlots: (file: File) => Promise<void>;
  resetRandomizationArmSlots: () => void;
}

const useRandomizationSlotsApi = (studyId: number): RandomizationSlotsApiHookReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [slots, setSlots] = useState<any>(null);
  const [endpoint, setEndpoint] = useState<string>('');
  const [loadingImport, setLoadingImport] = useState<boolean>(false);

  const loadSlots = async (): Promise<void> => {
    try {
      const { data: subject } = await getSubjectsConfig(studyId);
      const { data } = await getRandomisationSlots(studyId, subject.id);
      setSlots(data);
      setEndpoint(`randomisation_arms/roles/${subject.id}/import_slots/template`);
    } catch (e) {
      enqueueSnackbar(`${t('randomization_slots_load_error')}: ${e.message} `, {
        variant: 'error',
      });
    }
  };

  const postRandomizationArmSlots = async (file: File): Promise<void> => {
    try {
      setLoadingImport(true);
      const { data: subject } = await getSubjectsConfig(studyId);
      const result = await postBulkRandomisationSlots(studyId, subject.id, file);
      if (result.data) exportFile(result.data);
      enqueueSnackbar(t('randomization_slots_created_success'), {
        variant: 'success',
      });
      loadSlots();
    } catch (e) {
      enqueueSnackbar(`${t('randomization_slots_created_error')}: ${e.message} `, {
        variant: 'error',
      });
      throw e;
    } finally {
      setLoadingImport(false);
    }
  };

  const resetRandomizationArmSlots = async (): Promise<void> => {
    try {
      const { data: subject } = await getSubjectsConfig(studyId);
      await deleteAllRandomisationSlots(studyId, subject.id);
      loadSlots();
      enqueueSnackbar(t('randomization_reset_slots_success'), {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(`${t('randomization_reset_slots_error')}: ${e.message} `, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    loadSlots();
  }, []);

  return {
    slots,
    loadSlots,
    endpoint,
    loadingImport,
    postRandomizationArmSlots,
    resetRandomizationArmSlots
  };
};

export default useRandomizationSlotsApi;
