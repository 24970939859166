export const sites = {
  sites_title: 'Sites Management',
  sites_list_title: 'Sites List',
  sites_createButton: 'Create a site',
  sites_form_submit: 'Create site',
  sites_form_edit: 'Edit site',
  sites_search_placeholder: 'Search by label',
  sites_address: 'Address',
  sites_subSite: 'Subsites',
  sites_details_titleCreate: 'Create a site',
  sites_details_titleEdit: 'Edit a site',
  sites_details_subtitle: 'Enter site information',
  sites_details_goBack: 'Return to sites list',
  sites_details_subSite_create: 'Create a subsite',
  sites_details_subSite_list: 'Subsites List',
  sites_details_noSubsites: 'No subsites',
  sites_deleteConfirm_title: 'Delete a site',
  sites_deleteConfirm_text: 'Are you sure to delete this site? This action can\'t be undone',
  sites_details_subsite_deleteConfirm_title: 'Delete a subsite',
  sites_details_subsite_deleteConfirm_text: 'Are you sure to delete this subsite? This action can\'t be undone',
  sites_loadList_error: 'Sites list loading has failed',
  sites_create_error: 'Site creation has failed',
  sites_create_success: 'New site successfully created',
  sites_delete_error: 'Site deletion has failed',
  sites_delete_success: 'Site has been successfully deleted',
  sites_patch_error: 'Site update has failed',
  sites_patch_success: 'Site successfully updated',
  sites_subSite_create_error: 'Subsite creation has failed',
  sites_subSite_create_success: 'Subsite successfully created',
  sites_subSite_delete_error: 'Subsite deletion has failed',
  sites_subSite_delete_success: 'Subsite successfully deleted',
  sites_subSite_patch_error: 'Subsite update has failed',
  sites_subSite_patch_success: 'Subsite successfully updated',
};
