export const common = {
  common_cancel: 'Annuler',
  common_ok: 'Ok',
  common_createdAt: 'Créé le',
  common_createdBy: 'Créé par',
  common_updated: 'Modifié',
  common_updatedAt: 'Modifié le',
  common_updatedBy: 'Modifié par',
  common_status: 'Statut',
  common_profile: 'Profil',
  common_title: 'Titre',
  common_description: 'Description',
  common_integrity: 'Intégrité',
  common_type: 'Type',
  common_days_number: 'Décalage par rapport à la date de début de séquence (en jours)',
  common_keywords: 'Mots clés',
  common_points: 'Points',
  common_landscapes: 'Paysages',
  common_variable_name: 'Nom de variable',
  common_avg_amount_time_to_answer: 'Temps moyen de réponse',
  common_avg_amount_time_to_read: 'Temps moyen de lecture',
  common_mandatory: 'Obligatoire',
  common_complete: 'Complet',
  common_dependency: 'Dépendance',
  common_version: 'Version',
  common_answers: 'Réponses',
  common_loading: 'Chargement',
  common_inclusion: 'Inclusion',
  common_consent: 'Consentement',
  common_edit: 'Éditer',
  common_save: 'Sauvegarder',
  common_true: 'Vrai',
  common_false: 'Faux',
  common_yes: 'Oui',
  common_no: 'Non',
  common_format: 'Format',
  common_min: 'Min',
  common_max: 'Max',
  common_prefix: 'Préfixe (Optionnel)',
  common_suffix: 'Suffixe (Optionnel)',
  common_separator: 'Séparateur',
  common_step: 'Pas',
  common_label: 'Label',
  common_next: 'Réponse suivante',
  common_next_page: 'Suivant',
  common_asset: 'Asset',
  common_value: 'Valeur',
  common_direction: 'Sens',
  common_quantity: 'Quantité',
  common_constraint: 'Contrainte',
  common_unit: 'Unité',
  common_date: 'Date',
  common_investigator: 'Investigateur',
  common_intervenor: 'Intervenant',
  common_subject: 'Sujet',
  common_questionnaire: 'Questionnaire',
  common_content: 'Contenu',
  common_minutes: 'Minutes',
  common_required: 'Obligatoire',
  common_reset: 'Reset',
  common_actions: 'Actions',
  common_average_read_time: 'Temps de lecture moyen',
  common_delete: 'Supprimer',
  common_back: 'Retour',
  common_dot: 'Point',
  common_comma: 'Virgule',
  common_export_code: 'Code',
  common_study: 'Etude',
  common_question: 'Question',
  common_answers_number: 'Nombre de réponses',
  common_delete_success: 'Suppression effectuée avec succès',
  common_days: 'Jours',
  common_empty: 'A compléter',
  common_validated: 'Validé',
  common_start: 'Commencer',
  common_start_translator: 'Commencer la traduction',
  common_search_placeholder: 'Recherchez ici',
  common_copy_link: 'Copier le lien',
  optional: 'Optionnel',

  // Ajout Synakene

  common_secondary_school: 'Collégien',
  common_high_school: 'Lycéen',
  common_other_school: 'Etudiant, jeune trvailleur ou sans activité'
};
