export const question = {
  question_radiobutton: 'Radiobutton',
  question_checkbox: 'Checkbox',
  question_dropdown: 'Dropdown',
  question_slider: 'Slider',
  question_text: 'Text',
  question_number: 'Number',
  question_date: 'Date',
  question_hour: 'Hour',
  question_title: 'Question',
  question_description: 'Precisions (optional)',
  question_page_title: 'Question Details',
  question_page_goBack: 'Go back to questions list',
  question_form_save: 'Save question',
  question_form_general_title: 'General information',
  question_form_variableName: 'Variable name',
  question_hourFormat_half: '12 hours AM/PM',
  question_hourFormat_full: '24 hours',
  question_form_hour_title: 'Define hour',
  question_form_hour_start: 'Start hour (Optional)',
  question_form_hour_end: 'End hour (Optional)',
  question_form_number_title: 'Define number',
  question_form_number_decimale: 'Decimal number',
  question_form_text_title: 'Define text question',
  question_textType_line: 'Line',
  question_textType_paragraph: 'Paragraph',
  question_textType_email: 'Email',
  question_form_slider_title: 'Define slider question',
  question_form_slider_gradation: 'Display gradation',
  question_form_slider_subGradation: 'Display sub gradation',
  question_form_slider_answers_title: 'Slider value',
  question_form_slider_answers_add: 'Add a value',
  question_form_slider_answer_min: 'Min value',
  question_form_slider_answer_max: 'Max value',
  question_form_slider_answerForm_create_title: 'Add slider answer',
  question_form_slider_answerForm_edit_title: 'Edit slider answer',
  question_form_date_title: 'Define date',
  question_form_date_dateFormat: 'Format',
  question_form_date_min: 'Constraint start date',
  question_form_date_max: 'Constraint end date',
  question_form_date_min_off: 'No minimum',
  question_form_date_max_off: 'No maximum',
  question_dateFormat_year: 'YYYY',
  question_dateFormat_month: 'MM/YYYY',
  question_dateFormat_day: 'MM/DD/YYYY',
  question_dateUnit_year: 'Year',
  question_dateUnit_month: 'Month',
  question_dateUnit_day: 'Day',
  question_dateSens_plus: 'Plus',
  question_dateSens_minus: 'Minus',
  question_form_radio_title: 'Define a radiobutton question',
  question_form_dropdown_title: 'Define a dropdown question',
  question_form_checkbox_title: 'Define a checkbox question',
  question_form_checkbox_answers_title: 'Manage answers',
  question_form_checkbox_answers_add: 'Add answer',
  question_form_checkbox_variableName: 'Variable name',
  question_form_checkbox_answerForm_create_title: 'Create answer',
  question_form_checkbox_answerForm_edit_title: 'Edit answer',
  question_checkboxRule_off: 'None',
  question_checkboxRule_atLeast: 'At least',
  question_checkboxRule_atMost: 'At most',
  question_checkboxRule_exactly: 'Exactly',
  question_form_selector_answers_title: 'Answers',
  question_form_selector_answers_add: 'Add answer',
  question_form_selector_answerForm_edit_title: 'Edit value',
  question_form_selector_answerForm_create_title: 'Add value',
  question_load_error: 'Error while loading questionnaire detail',
  question_creation_error: 'Question creation has failed',
  question_creation_success: 'Question creation has succeeded',
  question_answer_orderAnswers_error: 'Reordering answers has failed',
  question_answer_deleteAnswer_error: 'Answer deletion has failed',
  question_answer_updateAnswer_success: 'Answer updated',
  question_answer_updateAnswer_error: 'Answer update has failed',
  question_answer_createAnswer_error: 'Answer creation has failed',
  question_answer_createAnswer_success: 'Answer created',
  question_delete_error: 'Question deletion has failed',
  question_order_error: 'Reordering questions has failed',
  question_answer_delete_title: 'Delete answer',
  question_answer_delete_text: 'Delete this answer?',
  inclusion_question: 'Inclusion Questions',
  secret_question: 'Secret Questions',
  stratification_question: 'Stratification Questions',
  question_patch_error: 'Question update has failed',
  question_patch_success: 'Question successfully updated',
  question_delete_success: 'Question successfully deleted',
};
