import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DiapasonLogo from '../atoms/DiapasonLogo';
import LoginBackground from '../../../icons/login-background.svg';
import gtm from '../../../lib/gtm';
import LoginForm from '../organisms/Login/LoginForm';
import LoginHeader from '../organisms/Login/LoginHeader';
import WelcomeTitle from '../atoms/WelcomeTitle';

const Login: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Diapason | Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundImage: `url(${LoginBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          width: '100%'
        }}
        className="bg-dark"
      >
        <LoginHeader />
        <Container maxWidth="sm" className="mx-12 w-4/5 max-w-none">
          <Box className="flex justify-between items-center">
            <Card className="rounded-l-2xl rounded-r-none bg-white p-6">
              <CardContent
                sx={{ height: '80vh' }}
                className="flex flex-col items-center justify-between"
              >
                <>
                  <Box
                    sx={{
                      height: 32,
                      '& > img': {
                        maxHeight: '100%',
                        width: 'auto'
                      }
                    }}
                  >
                    <DiapasonLogo />
                  </Box>
                  <Box className="flex flex-col items-center">
                    <Box
                      sx={{
                        alignItems: 'start',
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 1
                      }}
                    >
                      <Typography
                        className="text-dark"
                        gutterBottom
                        variant="h5"
                      >
                        {t('login_title')}
                      </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <LoginForm />
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Link
                      className="underline text-blue-link"
                      component={RouterLink}
                      to="/authentication/password-recovery"
                      variant="body2"
                    >
                      {t('login_button_forgot')}
                    </Link>
                  </Box>
                  <a
                    className="text-blue-link text-sm"
                    href="https://www.google.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.kastafiore.com
                  </a>
                </>
              </CardContent>
            </Card>
            <Box className="w-2/4 flex flex-col justify-between flex-wrap">
              <WelcomeTitle skipLine />
              <p className="text-white text-xl w-1/2">
                {t('login_description')}
              </p>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Login;
