export const invitation = {
  invitations_list_title: 'Liste des invitations',
  invitations_list_createButton: 'Ajouter invitation',
  invitations_list_deleteConfirm_title: 'Supprimer invitation',
  invitations_list_deleteConfirm_text: 'Supprimer cette invitation ?',
  invitation_channel_EMAIL: 'Email',
  invitation_channel_SMS: 'SMS',
  invitation_channel_NOTIFICATION: 'Notification',
  invitation_details_notification_content: 'Contenu de la notification',
  invitation_channel_PUBLICLINK: 'Lien public',
  invitation_channel: 'Canal de diffusion',
  invitation_denomination: 'Dénomination',
  invitation_details_sms_content: 'Contenu du SMS',
  invitation_details_sms_hello: 'Bonjour',
  invitation_details_sms_body: 'Message',
  invitation_details_sms_signature: 'Signature',
  invitation_details_email_content: 'Contenu du mail',
  invitation_details_email_title: 'Objet du mail',
  invitation_details_email_object: 'Objet',
  invitation_details_link_content: 'Contenu du lien',
  invitation_details_link_text: 'C\'est ici que vous pouvez paramétrer les invitations par liens',
  invitation_details_link_copy: 'Copier le lien',
  invitation_details_link_copy_valid: 'Copié dans le presse papier !',
  invitation_details_link_label: 'Lien public',
  invitation_details_recipient_from: 'Depuis',
  invitation_details_recipient_to: 'Vers',
  invitation_details_preview: 'Aperçu',
  invitation_denomination_NONE: 'Aucune',
  invitation_denomination_FIRSTNAME: 'Prénom',
  invitation_channel_LASTNAME: 'Nom',
  invitation_channel_LASTNAME_FIRSTNAME: 'Nom - Prénom',
  invitation_channel_FIRSTNAME_LASTNAME: 'Prénom - Nom',
  invitation_signature: 'Signature',
  invitation_method: 'Méthode raffermissant',
  invitation_method_select: 'Sélectionnez votre méthode de signature',
  invitation_signature_code_NONE: 'Aucune signature',
  invitation_signature_code_NAME: 'Utiliser nom',
  invitation_signature_code_LABEL: 'Entrer texte',
  invitation_title: 'Détails d\'une invitation',
  invitation_create_title: 'Création d\'une invitation',
  invitation_details_backToList: 'Retour aux invitations',
  invitation_details_recipient: 'Destinataires',
  invitation_preview_title: 'Aperçu',
  invitation_preview_signature_name: 'Votre nom',
  invitations_load_error: 'Erreur lors du chargement de la liste des invitations',
  invitations_config_load_error: 'Erreur lors du chargement de la configuration des invitations',
  invitations_details_load_error: 'Erreur lors du chargement des details de l\'invitation',
  invitations_details_put_success: 'Invitation correctement sauvegardée',
  invitations_details_put_error: 'Erreur durant la sauvegarde de l\'invitation',
  invitations_delete_success: 'Invitation supprimée',
  invitations_delete_error: 'Erreur lors de la suppression de l\'invitation',
  invitation_post_success: 'Invitation créée',
  invitation_post_error: 'Erreur lors de la création de l\'invitation',
  invitation_translation_title: 'Traduction des invitations',
  invitation_text: 'Attention, le choix du canal de diffusion dépend du choix de l\'expéditeur et du destinataire',
  invitation_details_notification_title: 'Titre',
  invitation_body_ps_text: 'est un espace réservé au lien d\'invitation qui sera remplacé par le lien réel lors de l\'envoi de l\'invitation.',
};
