import { AxiosResponse } from 'axios';
import { InclusionQuestionType, IntervenorsDefinitionType, InvestigatorsDefinitionType, ProfileBodyType, SecretQuestionType, SubjectsDefinitionType } from 'src/types/diapason/profileForm';
import { OrderItemType } from 'src/types/diapason/question';
import { deleteApi, getApi, patchApi, postApi, putApi } from './diapasonApi';

enum StudyRolesUrlEnm {
  GET_INTERVENORS_CONFIG = 'studies/{studyId}/roles/intervenors',
  POST_INTERVENOR_CONFIG = 'studies/{studyId}/roles/intervenors',
  PUT_INTERVENOR_CONFIG = 'studies/{studyId}/roles/intervenors/{intervenorId}',
  GET_SUBJECTS_CONFIG = 'studies/{studyId}/roles/subjects',
  PUT_SUBJECT_CONFIG = 'studies/{studyId}/roles/subjects',
  GET_INVESTIGATORS_CONFIG = 'studies/{studyId}/roles/investigators',
  PUT_INVESTIGATOR_CONFIG = 'studies/{studyId}/roles/investigators',
  DELETE_INTERVENOR_CONFIG = 'studies/{studyId}/roles/intervenors/{intervenorId}',
  POST_IDENTIFICATION_QUESTIONS = 'studies/{studyId}/roles/{roleId}/identification_questions',
  DELETE_IDENTIFICATION_QUESTIONS = 'studies/{studyId}/roles/{roleId}/identification_questions/{questionId}',
  PATCH_IDENTIFICATION_QUESTIONS = 'studies/{studyId}/roles/{roleId}/identification_questions/{questionId}',
  PUT_IDENTIFICATION_QUESTIONS = 'studies/{studyId}/roles/{roleId}/identification_questions/order',
  POST_STUDY_INCLUSION_QUESTIONS = 'studies/{studyId}/roles/{roleId}/inclusion_questions',
  PATCH_STUDY_INCLUSION_QUESTIONS = 'studies/{studyId}/roles/{roleId}/inclusion_questions/{questionId}',
  DELETE_STUDY_INCLUSION_QUESTIONS = 'studies/{studyId}/roles/{roleId}/inclusion_questions/{questionId}',
  PUT_STUDY_INCLUSION_QUESTIONS = 'studies/{studyId}/roles/{roleId}/inclusion_questions/order',
}

export const getIntervenorsConfig = (studyId: number): Promise<AxiosResponse<IntervenorsDefinitionType>> => {
  const url = StudyRolesUrlEnm.GET_INTERVENORS_CONFIG;
  return getApi<IntervenorsDefinitionType>(url, { studyId });
};

export const postIntervenorConfig = (studyId: number, profile: string): Promise<AxiosResponse<IntervenorsDefinitionType>> => {
  const url = StudyRolesUrlEnm.POST_INTERVENOR_CONFIG;
  return postApi<IntervenorsDefinitionType>(url, { studyId }, { profile });
};

export const putIntervenorConfig = (studyId: number, profile: ProfileBodyType): Promise<AxiosResponse<IntervenorsDefinitionType>> => {
  const url = StudyRolesUrlEnm.PUT_INTERVENOR_CONFIG;
  return putApi<IntervenorsDefinitionType>(url, { studyId, intervenorId: profile.id }, profile);
};

export const getSubjectsConfig = (studyId: number): Promise<AxiosResponse<SubjectsDefinitionType>> => {
  const url = StudyRolesUrlEnm.GET_SUBJECTS_CONFIG;
  return getApi<SubjectsDefinitionType>(url, { studyId });
};

export const putSubjectConfig = (studyId: number, profile: ProfileBodyType): Promise<AxiosResponse<IntervenorsDefinitionType>> => {
  const url = StudyRolesUrlEnm.PUT_SUBJECT_CONFIG;
  return putApi<IntervenorsDefinitionType>(url, { studyId }, profile);
};

export const getInvestigatorsConfig = (studyId: number): Promise<AxiosResponse<InvestigatorsDefinitionType>> => {
  const url = StudyRolesUrlEnm.GET_INVESTIGATORS_CONFIG;
  return getApi<InvestigatorsDefinitionType>(url, { studyId });
};

export const putInvestigatorConfig = (studyId: number, profile: ProfileBodyType): Promise<AxiosResponse<IntervenorsDefinitionType>> => {
  const url = StudyRolesUrlEnm.PUT_INVESTIGATOR_CONFIG;
  return putApi<IntervenorsDefinitionType>(url, { studyId }, profile);
};

export const deleteIntervenorConfig = (studyId: number, intervenorId: number): Promise<AxiosResponse<void>> => {
  const url = StudyRolesUrlEnm.DELETE_INTERVENOR_CONFIG;
  return deleteApi<void>(url, { studyId, intervenorId });
};

export const postApiIdentificationQuestion = <T>(
  studyId: number,
  roleId: number,
  question: SecretQuestionType
): Promise<AxiosResponse<T>> => {
  const url = StudyRolesUrlEnm.POST_IDENTIFICATION_QUESTIONS;
  return postApi<T>(url, { studyId, roleId }, question);
};

export const deleteApiIdentificationQuestion = (
  studyId: number,
  roleId: number,
  questionId: number
): Promise<AxiosResponse<void>> => {
  const url = StudyRolesUrlEnm.DELETE_IDENTIFICATION_QUESTIONS;
  return deleteApi<void>(url, { studyId, roleId, questionId });
};

export const patchApiIdentificationQuestion = <T>(
  studyId: number,
  roleId: number,
  question: SecretQuestionType
): Promise<AxiosResponse<T>> => {
  const url = StudyRolesUrlEnm.PATCH_IDENTIFICATION_QUESTIONS;
  return patchApi<T>(url, { studyId, roleId, questionId: question.id.toString() }, question);
};

export const putApiIdentificationQuestion = <T>(
  studyId: number,
  roleId: number,
  payload: OrderItemType[]
): Promise<AxiosResponse<T>> => {
  const url = StudyRolesUrlEnm.PUT_IDENTIFICATION_QUESTIONS;
  return putApi<T>(url, { studyId, roleId }, { identification_questions: payload });
};

export const postApiInclusionQuestion = <T>(
  studyId: number,
  roleId: number,
  question: InclusionQuestionType,
): Promise<AxiosResponse<T>> => {
  const url = StudyRolesUrlEnm.POST_STUDY_INCLUSION_QUESTIONS;
  return postApi<T>(url, { studyId, roleId }, { ...question });
};

export const patchApiInclusionQuestion = <T>(
  studyId: number,
  roleId: number,
  question: InclusionQuestionType,
): Promise<AxiosResponse<T>> => {
  const url = StudyRolesUrlEnm.PATCH_STUDY_INCLUSION_QUESTIONS;
  return patchApi<T>(url, { studyId, roleId, questionId: question.id }, { ...question });
};

export const deleteApiInclusionQuestion = (
  studyId: number,
  roleId: number,
  questionId: number,
): Promise<AxiosResponse<void>> => {
  const url = StudyRolesUrlEnm.DELETE_STUDY_INCLUSION_QUESTIONS;
  return deleteApi<void>(url, { studyId, roleId, questionId });
};

export const putApiInclusionQuestion = <T>(
  studyId: number,
  roleId: number,
  payload: OrderItemType[]
): Promise<AxiosResponse<T>> => {
  const url = StudyRolesUrlEnm.PUT_STUDY_INCLUSION_QUESTIONS;
  return putApi<T>(url, { studyId, roleId }, { inclusion_questions: payload });
};
