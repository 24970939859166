import { AxiosResponse } from 'axios';
import { ReorderGenericPayload } from 'src/types/diapason/question';
import { RandomisationArmsType, StratificationAnswerType, StratificationQuestionCreateType, StratificationQuestionType } from 'src/types/diapason/study';
import { deleteApi, getApi, postApi, patchApi, putApi } from './diapasonApi';

enum RandomizationUrlEnum {
  GET_RANDOMIZATION_ARMS = 'studies/{studyId}/randomisation_arms',
  DELETE_RANDOMIZATION_ARMS = 'studies/{studyId}/randomisation_arms/{armId}',
  POST_RANDOMIZATION_ARM = 'studies/{studyId}/randomisation_arms',
  PATCH_RANDOMIZATION_ARM = 'studies/{studyId}/randomisation_arms/{armId}',
  GET_STRATIFICATIONS = 'studies/{studyId}/roles/{roleId}/stratification_questions',
  DELETE_STRATIFICATION = 'studies/{studyId}/roles/{roleId}/stratification_questions/{stratId}',
  POST_STRATIFICATION = 'studies/{studyId}/roles/{roleId}/stratification_questions',
  GET_STRATIFICATION_DETAILS = 'studies/{studyId}/roles/{roleId}/stratification_questions/{stratId}',
  PATCH_STRATIFICATION_DETAILS = 'studies/{studyId}/roles/{roleId}/stratification_questions/{stratId}',
  DELETE_STRATIFICATION_ANSWER = 'studies/{studyId}/roles/{roleId}/stratification_questions/{stratId}/answers/{answerId}',
  POST_STRATIFICATION_ANSWER = 'studies/{studyId}/roles/{roleId}/stratification_questions/{stratId}/answers',
  PATCH_STRATIFICATION_ANSWER = 'studies/{studyId}/roles/{roleId}/stratification_questions/{stratId}/answers/{answerId}',
  PUT_STRATIFICATION_ORDER = 'studies/{studyId}/roles/{roleId}/stratification_questions/order',
  PUT_STRATIFICATION_ANSWER_ORDER = 'studies/{studyId}/roles/{roleId}/stratification_questions/{stratId}/answers/order',
  GET_RANDOMIZATION_ARMS_SLOTS = 'studies/{studyId}/randomisation_arms/roles/{roleId}/slots',
  DELETE_RANDOMIZATION_SLOTS = 'studies/{studyId}/randomisation_arms/roles/{roleId}/slots',
  POST_BULK_RANDOMIZATION_ARMS_SLOTS = 'studies/{studyId}/randomisation_arms/roles/{roleId}/import_slots',
}

export const getRandomizationArms = (studyId: number): Promise<AxiosResponse<RandomisationArmsType[]>> => {
  const url = RandomizationUrlEnum.GET_RANDOMIZATION_ARMS;
  return getApi<RandomisationArmsType[]>(url, { studyId });
};

export const getRandomisationSlots = (studyId: number, roleId: number): Promise<AxiosResponse<number>> => {
  const url = RandomizationUrlEnum.GET_RANDOMIZATION_ARMS_SLOTS;
  return getApi<number>(url, { studyId, roleId });
};
export const postBulkRandomisationSlots = (studyId: number, roleId: number, value: File): Promise<AxiosResponse<void | any>> => {
  const url = RandomizationUrlEnum.POST_BULK_RANDOMIZATION_ARMS_SLOTS;
  const headers = { 'Content-Type': 'application/json' };
  const formData = new FormData();
  formData.append('file', value);
  headers['Content-Type'] = 'multipart/form-data';
  return postApi<void>(url, { studyId, roleId }, formData, headers);
};

export const deleteRandomizationArm = (studyId: number, armId: number): Promise<AxiosResponse<void>> => {
  const url = RandomizationUrlEnum.DELETE_RANDOMIZATION_ARMS;
  return deleteApi<void>(url, { studyId, armId });
};

export const deleteAllRandomisationSlots = (studyId: number, roleId: number): Promise<AxiosResponse<void>> => {
  const url = RandomizationUrlEnum.DELETE_RANDOMIZATION_SLOTS;
  return deleteApi<void>(url, { studyId, roleId });
};

export const postRandomizationArm = (studyId: number, arm: RandomisationArmsType): Promise<AxiosResponse<RandomisationArmsType[]>> => {
  const url = RandomizationUrlEnum.GET_RANDOMIZATION_ARMS;
  return postApi<RandomisationArmsType[]>(url, { studyId }, arm);
};

export const patchRandomizationArm = (studyId: number, arm: Partial<RandomisationArmsType>): Promise<AxiosResponse<void>> => {
  const url = RandomizationUrlEnum.PATCH_RANDOMIZATION_ARM;
  return patchApi<void>(url, { studyId, armId: arm.id }, arm);
};

export const getStratificationQuestions = (studyId: number, roleId: number): Promise<AxiosResponse<StratificationQuestionType[]>> => {
  const url = RandomizationUrlEnum.GET_STRATIFICATIONS;
  return getApi<StratificationQuestionType[]>(url, { studyId, roleId });
};

export const deleteStratificationQuestion = (studyId: number, roleId: number, stratId: number): Promise<AxiosResponse<void>> => {
  const url = RandomizationUrlEnum.DELETE_STRATIFICATION;
  return deleteApi<void>(url, { studyId, roleId, stratId });
};

export const postStratificationQuestion = (
  studyId: number,
  roleId: number,
  strat: StratificationQuestionCreateType,
): Promise<AxiosResponse<void>> => {
  const url = RandomizationUrlEnum.POST_STRATIFICATION;
  return postApi<void>(url, { studyId, roleId }, strat);
};

export const getStratificationDetails = (
  studyId: number,
  roleId: number,
  stratId: number,
): Promise<AxiosResponse<StratificationQuestionType>> => {
  const url = RandomizationUrlEnum.GET_STRATIFICATION_DETAILS;
  return getApi<StratificationQuestionType>(url, { studyId, roleId, stratId });
};

export const patchStratifictionDetails = (
  studyId: number,
  roleId: number,
  stratId: number,
  body: Partial<StratificationQuestionType>,
): Promise<AxiosResponse<StratificationQuestionType>> => {
  const url = RandomizationUrlEnum.PATCH_STRATIFICATION_DETAILS;
  return patchApi<StratificationQuestionType>(url, { studyId, roleId, stratId }, body);
};

export const deleteStratificationAnswer = (
  studyId: number,
  roleId: number,
  stratId: number,
  answerId: number,
): Promise<AxiosResponse<void>> => {
  const url = RandomizationUrlEnum.DELETE_STRATIFICATION_ANSWER;
  return deleteApi<void>(url, { studyId, roleId, stratId, answerId });
};

export const postStratificationAnswer = (
  studyId: number,
  roleId: number,
  stratId: number,
  answer: StratificationAnswerType,
): Promise<AxiosResponse<StratificationQuestionType>> => {
  const url = RandomizationUrlEnum.POST_STRATIFICATION_ANSWER;
  return postApi<StratificationQuestionType>(url, { studyId, roleId, stratId }, answer);
};

export const patchStratificationAnswer = (
  studyId: number,
  roleId: number,
  stratId: number,
  answer: StratificationAnswerType,
): Promise<AxiosResponse<StratificationQuestionType>> => {
  const url = RandomizationUrlEnum.PATCH_STRATIFICATION_ANSWER;
  return patchApi<StratificationQuestionType>(url, { studyId, roleId, stratId, answerId: answer.id }, answer);
};

export const putStratificationOrder = (
  studyId: number,
  roleId: number,
  body: ReorderGenericPayload,
): Promise<AxiosResponse<StratificationQuestionType[]>> => {
  const url = RandomizationUrlEnum.PUT_STRATIFICATION_ORDER;
  return putApi<StratificationQuestionType[]>(url, { studyId, roleId }, body);
};

export const putStratificationAnswersOrder = (
  studyId: number,
  roleId: number,
  stratId: number,
  body: ReorderGenericPayload,
): Promise<AxiosResponse<StratificationQuestionType>> => {
  const url = RandomizationUrlEnum.PUT_STRATIFICATION_ANSWER_ORDER;
  return putApi<StratificationQuestionType>(url, { studyId, roleId, stratId }, body);
};
