export const sequence = {
  sequence_list_title: 'Sequences list',
  sequence_list_createButton: 'Create sequence',
  sequence_create_title: 'Create a sequence',
  sequence_create_subtitle: 'To create a sequence please do fill the form',
  sequence_numberDisplayed: '{{number}} Results',
  sequence_destinataire: 'Affected roles',
  sequence_delay: 'Start delay',
  sequence_delay_required: 'Start delay is required !',
  sequence_delay_suffix: 'days after subject inclusion',
  sequence_duration: 'Duration',
  sequence_duration_suffix: 'days',
  sequence_random_arms: 'Randomization arms',
  sequence_card_button: 'Modify',
  sequence_searchPlaceholder: 'Search by title',
  sequence_details_title: 'Sequence details',
  sequence_details_goBack: 'Go back to sequence',
  sequence_details_rolesInput: 'Affected roles',
  sequence_details_armsInput: 'Randomisation arms',
  sequence_details_delayInput: 'Start delay',
  sequence_details_durationInput: 'Duration',
  sequence_details_infos_title: 'Sequence information',
  sequence_details_content_add: 'Add content',
  sequence_details_content_popover_title: 'Select a content',
  sequence_details_content_placeholder: 'Add content',
  sequence_details_questionnaire_add: 'Add questionnaire',
  sequence_details_questionnaire_popover_title: 'Select a questionnaire',
  sequence_details_questionnaire_placeholder: 'Questionnaires',
  sequence_details_components_title: 'Components',
  sequence_loadList_error: 'Error while loading sequences list',
  sequence_loadDetails_error: 'Error while loading sequence details',
  sequence_create_success: 'The sequence has been successfully created',
  sequence_create_error: 'Sequence creation has failed',
  sequence_update_error: 'Sequence update has failed',
  sequence_update_success: 'Sequence update has succeeded',
  sequence_delete_success: 'The sequence has been successfully deleted',
  sequence_delete_error: 'Sequence deletion has failed',
  sequence_list_deleteConfirm_title: 'Delete sequence',
  sequence_list_deleteConfirm_text: 'Are you sure to delete this sequence? This action can\'t be undone',
  sequence_details_questionnaire_delete_title: 'Remove questionnaire',
  sequence_details_questionnaire_delete_text: 'Delete this questionnaire from the sequence ?',
  sequence_details_content_delete_title: 'Remove content',
  sequence_details_content_delete_text: 'Delete this content from the sequence ?',
  sequence_component_create_success: 'Content added successfully',
  sequence_component_create_error: 'Error during content addition',
  sequence_component_delete_success: 'Content removed successfully',
  sequence_component_delete_error: 'Error during content removal',
  content_translation_title: 'Contents translations',
  sequence_reminders: 'Reminders',
  sequence_reminders_text: 'Reminders will be used to send to subjects / intervenors / investigators to complete their sequences',
  sequence_title: 'Sequence title',
  sequence_welcome: 'Welcome message',
  sequence_translation_title: 'Sequences translations',
  sequence_order_error: 'Reordering components has failed',
  sequence_details_reminder_add: 'Add Reminder',
  sequence_reminder_title: 'Reminder',
  sequence_reminder_put_success: 'Reminder correctly saved',
  sequence_reminder_put_error: 'Error while saving reminder details',
  sequence_reminder_delete_success: 'Reminder correctly removed',
  sequence_reminder_delete_error: 'Error while removing reminder',
  sequence_reminder_post_success: 'Reminder correctly created',
  sequence_reminder_post_error: 'Error while creating reminder',
  sequence_reminder_txt: 'The duration of the corresponding sequence is ',
  sequence_reminder_create_title: 'Create a reminder',
  reminders_list_deleteConfirm_title: 'Delete reminder',
  reminders_list_deleteConfirm_text: 'Delete this reminder ?',
  sequence_description_first_title: 'Content & Questionnaire',
  sequence_description_second_title: 'Schedule',
  sequence_description_third_title: 'Reminders',
  sequence_description_fourth_title: 'Sequence',
  sequence_description_first_content: 'A sequence can contain questionnaire(s) and content(s).  You can choose contents and questionnaires that you have already created in the Questionnaire and Content sections in this platform. You can also set their order by dragging them up and down.',
  sequence_description_second_content: 'For each sequence, you can set an schedule. The schedule is set based on the inclusion date of the user. For example, you can set your second sequence to be displayed to the user, x days after his/her inclusion.',
  sequence_description_third_content: 'You can create reminders for the sequences. A reminder can be sent through SMS, email, and mobile notification. You should configure the content and choose the channel of your reminder, as well as, setting an schedule for your reminder based on the start date of the sequence.',
  sequence_description_fourth_content: 'In the next page, you can create a study sequence. Some studies such as longitudinal researches can include multiple stages or steps at different intervals of time. Here these study steps are called sequences.',
};
