export const questionnaire = {
  questionnaire_list_title: 'Liste des questionnaires',
  questionnaire_list_createButton: 'Nouveau',
  questionnaire_list_create_title: 'Créer Questionnaire',
  questionnaire_list_create_subtitle: 'Saisissez les informations',
  questionnaire_searchPlaceholder: 'Rechercher par titre',
  questionnaire_numberDisplayed: '{{number}} Résultats',
  questionnaire_card_questions: 'Questions',
  questionnaire_card_button: 'Modifier',
  questionnaire_card_deleteConfirm_title: 'Supprimer questionnaire',
  questionnaire_card_deleteConfirm_text: 'Supprimer ce questionnaire ? Cette action ne peut pas être annulée',
  questionnaire_details_title: 'Détails du questionnaire',
  questionnaire_details_goBack: 'Retour aux questionnaires',
  questionnaire_details_tab_config: 'Configuration',
  questionnaire_details_tab_trads: 'Traductions',
  questionnaire_details_questions_createButton: 'Nouvelle question',
  questionnaire_question_create_title: 'Créer une question',
  questionnaire_question_create_subtitle: 'Remplissez le formulaire ci-dessous pour initialiser une nouvelle question',
  questionnaire_translation_header_original: 'Traduction originale',
  questionnaire_translation_header_translation: 'Traduction',
  questionnaire_translation_header_valid: 'Est valide ?',
  questionnaire_translation_questionnaireTitle: 'Titre du questionnaire',
  questionnaire_translation_questionnaireDescription: 'Description du questionnaire',
  questionnaire_translation_questions: 'Traductions des questions',
  questionnaire_translation_title: 'Traductions du questionnaire',
  questionnaire_translation_goBack: 'Retour aux traductions',
  questionnaire_update_error: 'Erreur lors de la mise à jour du questionnaire',
  questionnaire_update_success: 'La mise à jour du questionnaire a été effectué avec succès',
  questionnaire_loadList_error: 'Erreur lors du chargement de la liste des questionnaires',
  questionnaire_load_error: 'Erreur lors du chargement du questionnaires',
  questionnaire_delete_error: 'Erreur lors de la suppression du questionnaire',
  questionnaire_delete_success: 'La suppression du questionnaire a été effectué avec succès',
  questionnaire_create_success: 'Nouveau questionnaire créé avec succès',
  questionnaire_create_error: 'Erreur lors de la création du questionnaire',
  questionnaire_role_delete_success: 'Rôle ajouté',
  questionnaire_role_delete_error: 'Erreur lors de l\'ajout du rôle',
  questionnaire_role_post_success: 'Rôle retiré',
  questionnaire_role_post_error: 'Erreur lors de l’enlèvement du rôle',
  questionnaire_Standardized: 'Questionnaires standardisés',
  questionnaire_Study: 'Questionnaires d\'étude',
  questionnaire_questions_deleteConfirm_title: 'Supprimer question',
  questionnaire_questions_deleteConfirm_text: 'voulez-vous supprimer cette question? Cette action ne peut pas être annulée.'
};
