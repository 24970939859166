import { ReactElement, ChangeEvent } from 'react';
import {
  Select,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { generateUuid } from '../../../utils/uuid';

interface LabelSelectProps {
  value: string | number | null;
  onChange: (value: string | number) => void;
  options: ReactElement[];
  placeholder?: string;
  disabled?: boolean;
  native?: boolean;
  color?: 'white' | 'dark';
}

const LabelSelect = ({ value, onChange, placeholder, options, disabled, native, color = 'dark' }: LabelSelectProps) => {
  const id = generateUuid();

  return (
    <FormControl
      variant="outlined"
      fullWidth
    >
      <InputLabel
        id={id}
        className={`text-${color}`}
      >
        {placeholder}
      </InputLabel>
      <Select
        labelId={id}
        className={`text-${color}`}
        value={value || ''}
        onChange={(e: ChangeEvent<{ value: string | number; }>) => onChange(e.target.value)}
        sx={{
          '&.Mui-disabled': {
            color: color === 'dark' ? 'black' : 'white'
          }
        }}
        disabled={disabled}
        native={native}
        label={placeholder}
        fullWidth
      >
        {options}
      </Select>
    </FormControl>
  );
};

export default LabelSelect;
