export const finalParams = {
  final_params_app_name: 'Application Name',
  final_params_app_name_content: 'Please choose a name for your research application. This name will be displayed in the application.',
  final_params_app_logo: 'Logo',
  final_params_app_content: 'The following logo will be displayed in the application. You can change it by clicking on the replace button.',
  final_params_app_add_button_text: 'Replace Logo',
  final_params_app_reset_button_text: 'Reset Logo',
  final_params_app_theme: 'Color Theme',
  final_params_app_theme_content: 'Please choose the color theme for your application.',
};
