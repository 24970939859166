import { AxiosResponse } from 'axios';
import { StudyRoleType } from 'src/types/diapason/session';
import { LangType, MassCommunicationType, StudyByIdType, StudyConfigCodeEnum, StudyConfigType } from '../types/diapason/study';
import { QuestionnaireType } from '../types/diapason/questionnaire';
import { deleteApi, getApi, patchApi, postApi } from './diapasonApi';

enum StudyUrlEnm {
  GET_ROLES = 'studies/{studyId}/roles',
  GET_STUDY_BY_ID = 'studies/{studyId}',
  GET_RANDOMIZATION_ARMS = 'studies/{studyId}/randomisation_arms',
  GET_STUDY_LANG_CONFIG = 'studies/{studyId}/langs',
  POST_STUDY_LANG = 'studies/{studyId}/langs/{lang}',
  DELETE_STUDY_LANG = 'studies/{studyId}/langs/{lang}',
  GET_STUDY_QUESTIONNAIRES = 'studies/{studyId}/questionnaires',
  DELETE_STUDY_QUESTIONNAIRES = 'studies/{studyId}/questionnaires/{questionnaireId}',
  POST_STUDY_QUESTIONNAIRES = 'studies/{studyId}/questionnaires',
  GET_STUDY_CONFIG = 'studies/{studyId}/configs',
  PATCH_STUDY_CONFIG = 'studies/{studyId}/configs',
  SEND_MASS_COMMUNICATION = 'studies/{studyId}/mass_communication',
}

export const getApiStudyLang = (studyId: number): Promise<AxiosResponse<LangType>> => {
  const url = StudyUrlEnm.GET_STUDY_LANG_CONFIG;
  return getApi<LangType>(url, { studyId });
};

export const getApiStudyById = (studyId: number): Promise<AxiosResponse<StudyByIdType>> => {
  const url = StudyUrlEnm.GET_STUDY_BY_ID;
  return getApi<StudyByIdType>(url, { studyId });
};

export const postApiStudyLang = (studyId: number, lang: string): Promise<AxiosResponse<LangType>> => {
  const url = StudyUrlEnm.POST_STUDY_LANG;
  return postApi<LangType>(url, { studyId, lang }, null);
};

export const deleteApiStudyLang = (studyId: number, lang: string): Promise<AxiosResponse<void>> => {
  const url = StudyUrlEnm.DELETE_STUDY_LANG;
  return deleteApi<void>(url, { studyId, lang });
};

export const getApiQuestionnaires = (studyId: number): Promise<AxiosResponse<QuestionnaireType[]>> => {
  const url = StudyUrlEnm.GET_STUDY_QUESTIONNAIRES;
  return getApi<QuestionnaireType[]>(url, { studyId });
};

export const deleteApiQuestionnaire = (studyId: number, questionnaireId: number): Promise<AxiosResponse<void>> => {
  const url = StudyUrlEnm.DELETE_STUDY_QUESTIONNAIRES;
  return deleteApi<void>(url, { studyId, questionnaireId });
};

export const postApiQuestionnaires = (studyId: number, questionnaire: QuestionnaireType): Promise<AxiosResponse<QuestionnaireType[]>> => {
  const url = StudyUrlEnm.POST_STUDY_QUESTIONNAIRES;
  return postApi<QuestionnaireType[]>(url, { studyId }, questionnaire);
};

export const getRoles = (studyId: number): Promise<AxiosResponse<StudyRoleType[]>> => {
  const url = StudyUrlEnm.GET_ROLES;
  return getApi<StudyRoleType[]>(url, { studyId });
};

export const getStudyConfigs = (studyId: number): Promise<AxiosResponse<StudyConfigType[]>> => {
  const url = StudyUrlEnm.GET_STUDY_CONFIG;
  return getApi<StudyConfigType[]>(url, { studyId });
};

export const sendMassCommunication = (studyId: number, mass_communication: MassCommunicationType): Promise<AxiosResponse<void>> => {
  const url = StudyUrlEnm.SEND_MASS_COMMUNICATION;
  return postApi<void>(url, { studyId }, mass_communication);
};

export const putStudyConfigs = (studyId: number, code: StudyConfigCodeEnum, value: string | File, reset?: string): Promise<AxiosResponse<void>> => {
  const url = StudyUrlEnm.PATCH_STUDY_CONFIG;
  const headers = { 'Content-Type': 'application/json' };
  let data : any = { code, value };
  if (code === StudyConfigCodeEnum.LOGO) {
    const formData = new FormData();
    formData.append('value', value);
    formData.append('code', code);
    headers['Content-Type'] = 'multipart/form-data';
    data = formData;
  }
  return patchApi<void>(url, { studyId, }, data, false, headers, { reset });
};
