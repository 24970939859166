export const question = {
  question_radiobutton: 'Choix simple',
  question_checkbox: 'Choix multiple',
  question_dropdown: 'Liste déroulante',
  question_slider: 'Curseur',
  question_text: 'Texte',
  question_number: 'Nombre',
  question_date: 'Date',
  question_hour: 'Heure',
  question_title: 'Question',
  question_description: 'Précisions (optionnel)',
  question_page_title: 'Vue détaillée d\'une question',
  question_page_goBack: 'Retour aux questions',
  question_form_save: 'Sauvegarder question',
  question_form_general_title: 'Informations générales',
  question_form_variableName: 'Nom de variable',
  question_hourFormat_half: '12 heures AM/PM',
  question_hourFormat_full: '24 heures',
  question_form_hour_title: 'Définition de l\'heure',
  question_form_hour_start: 'Heure fixe début (Optionnel)',
  question_form_hour_end: 'Heure fixe fin (Optionnel)',
  question_form_number_title: 'Définition de la question de type nombre',
  question_form_number_decimale: 'Nombre de décimales',
  question_form_text_title: 'Définition de la question de type texte',
  question_textType_line: 'Ligne',
  question_textType_paragraph: 'Paragraphe',
  question_textType_email: 'Email',
  question_form_slider_title: 'Définition de la question curseur',
  question_form_slider_gradation: 'Afficher gradation',
  question_form_slider_subGradation: 'Afficher sous gradation',
  question_form_slider_answers_title: 'Valeur du curseur',
  question_form_slider_answers_add: 'Ajouter une valeur',
  question_form_slider_answer_min: 'Valeur min',
  question_form_slider_answer_max: 'Valeur max',
  question_form_slider_answerForm_create_title: 'Ajouter une réponse pour la question de type curseur',
  question_form_slider_answerForm_edit_title: 'Editer une réponse pour la question de type curseur',
  question_form_date_title: 'Définition de la question de type date',
  question_form_date_dateFormat: 'Format',
  question_form_date_min: 'Date de début de la contrainte',
  question_form_date_max: 'Date de fin de la contrainte',
  question_form_date_min_off: 'Pas de minimum',
  question_form_date_max_off: 'Pas de maximum',
  question_dateFormat_year: 'AAAA',
  question_dateFormat_month: 'MM/AAAA',
  question_dateFormat_day: 'JJ/MM/AAAA',
  question_dateUnit_year: 'Année',
  question_dateUnit_month: 'Mois',
  question_dateUnit_day: 'Jour',
  question_dateSens_plus: 'Plus',
  question_dateSens_minus: 'Moins',
  question_form_radio_title: 'Définition d\'une question à choix simple',
  question_form_dropdown_title: 'Définition d\'une question de type liste déroulante',
  question_form_checkbox_title: 'Définition de la question à choix multiple',
  question_form_checkbox_answers_title: 'Gestion des réponses',
  question_form_checkbox_answers_add: 'Ajouter réponse',
  question_form_checkbox_variableName: 'Nom de variable',
  question_form_checkbox_answerForm_create_title: 'Créer réponse',
  question_form_checkbox_answerForm_edit_title: 'Editer réponse',
  question_checkboxRule_off: 'Aucun',
  question_checkboxRule_atLeast: 'Au moins',
  question_checkboxRule_atMost: 'Au plus',
  question_checkboxRule_exactly: 'Exactement',
  question_form_selector_answers_title: 'Gestion des réponses',
  question_form_selector_answers_add: 'Ajouter une réponse',
  question_form_selector_answerForm_edit_title: 'Edition d\'une valeur',
  question_form_selector_answerForm_create_title: 'Créer une valeur',
  question_load_error: 'Erreur lors du chargement des details du questionnaire',
  question_creation_error: 'Erreur lors de la création de la question',
  question_creation_success: 'La création de la question a été effectué avec succès',
  question_answer_orderAnswers_error: 'Erreur lors de la mise à jour de l\'ordre des réponses',
  question_answer_deleteAnswer_error: 'Erreur lors de la suppression de la réponse',
  question_answer_updateAnswer_success: 'Réponse mise à jour',
  question_answer_updateAnswer_error: 'Erreur lors de la mise à jour de la réponse',
  question_answer_createAnswer_success: 'Réponse créée',
  question_answer_createAnswer_error: 'Erreur lors de la création de la réponse',
  question_delete_error: 'Erreur lors de la suppression de la question',
  question_answer_delete_title: 'Supprimer réponse',
  question_answer_delete_text: 'Êtes-vous sur de vouloir supprimer cette réponse ?',
  question_order_error: 'Erreur lors de la mise à jour de l\'ordre des questions',
  inclusion_question: 'Questions d\'inclusion',
  secret_question: 'Questions secrètes',
  stratification_question: 'Questions de Stratification',
  question_patch_error: 'Erreur lors de la mise à jour de la question',
  question_patch_success: 'Question correctement mis à jour',
  question_delete_success: 'Question supprimée avec succés',
};
