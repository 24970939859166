export const stratification = {
  stratification_details_title: 'Stratification details',
  stratification_details_answers_button: 'New answer',
  stratification_details_answers_title: 'Answers',
  stratification_details_answer_edit: 'Edit answer',
  stratification_details_answer_create: 'Create answer',
  stratification_details_deleteConfirm_title: 'Delete answer',
  stratification_details_deleteConfirm_text: 'Delete this answer? This action can\'t be undone',
  stratification_details_load_error: 'Error while loading stratification details',
  stratification_details_patch_error: 'Error while updating stratification details',
  stratification_details_patch_success: 'Stratification details correctly updated',
  stratification_details_delete_answer_success: 'Stratification answer correctly removed',
  stratification_details_delete_answer_error: 'Error while removing answer',
  stratification_details_post_answer_success: 'Stratification answer correctly created',
  stratification_details_post_answer_error: 'Error while creating answer',
  stratification_details_patch_answer_success: 'Stratification answer correctly updated',
  stratification_details_patch_answer_error: 'Error while updating answer',
  stratification_details_reorder_answer_error: 'Error while reordering answers',
};
