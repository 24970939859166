// import { Box } from '@material-ui/core';
import { memo, ReactNode } from 'react';

interface DiapasonContentWrapperProps {
  children: ReactNode
}

const DiapasonContentWrapper = ({ children }: DiapasonContentWrapperProps) => <div className="rounded-xl p-6 bg-grey-background" style={{ height: 'calc(100vh - 64px)' }}>{children}</div>;

export default memo(DiapasonContentWrapper);
