export const InvitationUsers = {
  invitation_users_recipient_name: 'Nom',
  invitation_users_recipient_prename: 'Prénom',
  invitation_users_recipient_canal: 'Canal',
  invitation_users_recipient_email: 'Email',
  invitation_users_recipient_phone_number: 'Téléphone',
  invitation_users_recipient_lang: 'Langue',
  invitation_users_recipient_lang_en: 'Anglais',
  invitation_users_recipient_lang_fr: 'Français',
  invitation_users_recipient_contact_information: 'Moyen de contact',
  invitation_users_status: 'Statut',
  invitation_users_date: 'Date d\'invitation',
  invitation_user_create_title: 'Inviter un investigateur',
  invitation_user_create_subtitle: 'Créer une invitation pour un investigateur',
  PENDING_UNIQUE: 'Invitation envoyée',
  invitation_user_create_bulk: 'Inviter plusieurs investigateurs',
  invitation_user_create_bulk_subtitle: 'Créer plusieurs invitations pour investigateurs',
  invitation_user_create_bulk_description: 'Vous pouvez inviter plusieurs investigateurs en une fois en téléchargeant un fichier CSV. Téléchargez le modèle que vous pouvez remplir et télécharger ici.',
  invitation_user_upload: 'Importer votre fichier CSV',
  invitation_user_download_template: 'Télécharger le modèle CSV',
  invitation_user_load_success: 'Invitations chargées',
  invitation_user_load_error: 'Erreur lors du chargement des invitations',
  langs_load_error: 'Erreur lors du chargement des langues',
  invitation_user_load_create_success: 'Invitation créée',
  invitation_user_load_create_error: 'Erreur lors de la création de l\'invitation',
  invitations_user_bulk_create_success: 'Invitations créées',
  invitations_user_bulk_create_error: 'Erreur lors de la création des invitations',
};
