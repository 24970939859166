import { OutputData } from '@editorjs/editorjs';
import { LangEnum, CreatedByType } from './common';

export interface LangType {
  primary_lang: LangEnum
  additional_langs: LangEnum[]
}
export interface StudyByIdType {
  name: string
  version: number
  slug?: string
  primary_lang: string
  langs: string[]
  is_active: boolean
  status: string
  is_gamified: boolean
}

export interface StudyType {
  lang: LangType;
  id: number;
}

export interface SiteType {
  id?: number;
  label: string;
  description: string;
  address: string;
  subsites: SubSiteType[];
}

export interface SubSiteType {
  id?: number;
  label: string;
}

export interface StudyContentsType {
  id: number;
  type: StudyTypeEnum;
  label: string;
  avg_amount_time_to_read: number;
  content: OutputData;
  created_by?: CreatedByType;
  last_update_by?: CreatedByType;
}

export enum StudyTypeEnum {
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  SEQBEGIN = 'SEQ-BEGIN',
  SEQEND = 'SEQ-END',
  CONSENT = 'CONSENT',
  HOMESTUDY = 'HOME-STUDY',
  ONBOARDINGSTUDY = 'ONBOARDING-STUDY',
  DETAILEDHOMESTUDY = 'DETAILED-HOME-STUDY',
  INELIGIBLEINCLUSION = 'INELIGIBLE-INCLUSION',
  LOGGUEDHOMESTUDY = 'LOGGUED-HOME-STUDY'
}

export const studyContents = {
  'study-presentations': {
    'ONBOARDING-STUDY': 'ONBOARDING-STUDY',
    'HOME-STUDY': 'HOME-STUDY',
    'DETAILED-HOME-STUDY': 'DETAILED-HOME-STUDY',
    'INELIGIBLE-INCLUSION': 'INELIGIBLE-INCLUSION',
    'LOGGUED-HOME-STUDY': 'LOGGUED-HOME-STUDY',
  },
  consent: {
    CONSENT: 'CONSENT',
  },
  'sequence-content': {
    'SEQ-BEGIN': 'SEQ-BEGIN',
    'SEQ-END': 'SEQ-END',
    QUESTIONNAIRE: 'QUESTIONNAIRE',
  }
};
export const ContentTitleTranslations = {
  'study-presentations': 'navBar_sections_study_presentations',
  consent: 'navBar_sections_consent',
  'sequence-content': 'navBar_sections_sequence_content',
};

export interface StudySequenceType {
  id?: number | null
  start_delay: number
  duration: number
  label: string
  targeted_roles: TargetedRoleType[]
  randomisation_arms: RandomisationArmsType[],
  last_update_by?: CreatedByType
}

export interface StudySequenceCreationType {
  start_delay: number
  duration: number
  label: string
  targeted_roles: number[]
  randomisation_arms: number[]
}

export interface TargetedRoleType {
  id: number,
  label: string,
}

export interface RandomisationArmsType {
  id?: number,
  code: string,
  label: string
}

export interface SequenceComponentType {
  id?: number
  questionnaire_id: null | number
  questionnaire_title: null | string
  avg_amount_time_to_answer: null | number
  content_id: null | number
  content_label: null | string
  avg_amount_time_to_read: null | number
  order?: number

}

export interface SequenceDetailsType {
  id: number
  start_delay: number
  duration: number
  label: string
  targeted_roles: TargetedRoleType[]
  randomisation_arms: RandomisationArmsType[]
  components: SequenceComponentType[]
  reminders: any[] // TODO: define reminders
  last_update_by?: CreatedByType
}

export interface InvitationType {
  id?: number
  ref_invitation_config_id: number
  created_by?: CreatedByType
  last_update_by?: CreatedByType
  from_role_label: string
  to_role_label: string
  from_role_id?: number | null
  to_role_id: number
  channel: InvitationChannelEnum | ''
  email: InvitationEmailType | {}
  sms: InvitationSmsType | {}
  public_link: string // TODO: ensure that type is correct
  from_role?: string
  to_role?: string
}

export interface InvitationUserType {
  firstname: string;
  lastname: string;
  channel: InvitationChannelEnum | '';
  email?: string;
  invitation_config_id?: number;
  phone_number?: string;
  lang: string;
}

export interface InvitationUserGet {
  id: number;
  firstname: string;
  lastname: string;
  channel: InvitationChannelEnum | '';
  contact_info: string;
  status: string
  invitation_date: string;
}

export interface InvitationPostUserType {
  channel: InvitationChannelEnum | '';
  invitation_config_id?: number;
  lang: string;
  target_user: {
    firstname: string;
    lastname: string;
    email?: string;
    phone_number?: string;
  }
}

export enum InvitationChannelEnum {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUBLIC_LINK = 'PUBLIC_LINK',
}

export enum ChannelEnum {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  NOTIFICATION = 'NOTIFICATION',
}

export enum ReminderChannelEnum {
  NOTIFICATION = 'NOTIFICATION',
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

export interface InvitationSmsType {
  welcome: string
  denomination: InvitationDenomitationEnum
  body: string
  signature: string
  signature_code: SignatureCodeEnum
}

export type NotificationType = {
  title: string,
  body: string
};
export interface ReminderNotificationType {
  title: string
  body: string
}

export interface InvitationEmailType extends InvitationSmsType {
  title: string
}

export enum InvitationDenomitationEnum {
  NONE = 'NONE',
  FIRSTNAME = 'FIRSTNAME',
  LASTNAME = 'LASTNAME',
  FIRSTNAMELASTNAME = 'FIRSTNAME-LASTNAME',
  LASTNAMEFIRSTNAME = 'LASTNAME-FIRSTNAME'
}

export enum SignatureCodeEnum {
  LABEL = 'LABEL',
  FIRSTNAME_LASTNAME = 'FIRSTNAME_LASTNAME',
  NONE = 'NONE'
}

export interface InvitationConfigType {
  id: number
  from_role: string
  to_role: string
  from_role_label: string
  to_role_label: string
  from_role_id: number | null
  to_role_id: number | null
  channel: InvitationChannelEnum
  denominations: InvitationDenomitationEnum[]
  order: number
}

export interface InvitationToCoreType {
  ref_invitation_config_id: number
  role: string;
  role_label: string;
  role_id: number;
}

export interface InvitationToType extends InvitationToCoreType {
  channels: { channelName :InvitationChannelEnum, config_id: number }[];
}

export interface InvitationFromType extends InvitationToCoreType {
  to: InvitationToType[];
}

export interface SequenceBodyType {
  start_delay?: number
  duration?: number
  label?: string
}

export interface SequenceComponentBodyType {
  type: string,
  id: number
}

export interface ContentType {
  created_by?: CreatedByType
  last_update_by?: CreatedByType
  channel: ReminderChannelEnum | ''
  email: InvitationEmailType | {}
  sms: InvitationSmsType | {}
  notification: ReminderNotificationType | {}
}
export interface ReminderType extends ContentType {
  id?: number
  offset_delay: number
  duration?: number
}

export interface MassCommunicationType extends ContentType {
  targeted_roles: TargetedRoleType[]
  sendOnDate: Date | null
}

export type StudyConfigType = RandomizationConfigType;

export interface RandomizationConfigType {
  id: number,
  code: StudyConfigCodeEnum,
  value: RandomizationValueEnum,
  type: RandomizationTypeEnum,
  order: number
}

export enum RandomizationValueEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  CLUSTER = 'CLUSTER'
}

export enum RandomizationMethodEnum {
  AUTO = 'AUTO',
  FILE = 'FILE'
}

export enum RandomizationTypeEnum {
  BOOLEAN = 'BOOLEAN'
}

export enum StudyConfigCodeEnum {
  RANDOMISATION_TYPE = 'RANDOMISATION_TYPE',
  APP_NAME = 'APP_NAME',
  LOGO = 'LOGO',
  COLOR_THEME = 'COLOR_THEME',
}

export interface StratificationQuestionCreateType {
  export_code: string,
  label: string,
}

export interface StratificationQuestionType extends StratificationQuestionCreateType {
  id: number,
  version: number,
  order: number,
  answers: StratificationAnswerType[]
}

export interface StratificationAnswerType {
  id?: number,
  version?: number,
  export_code: string,
  label: string,
  order?: number,
}
