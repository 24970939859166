import { AxiosResponse } from 'axios';
import { InvitationType, InvitationConfigType, InvitationPostUserType, InvitationUserGet } from 'src/types/diapason/study';
import { getApi, postApi, deleteApi, putApi } from './diapasonApi';

enum InvitationsUrlEnm {
  GET_STUDY_INVITATIONS = 'studies/{studyId}/invitations',
  GET_STUDY_INVITATIONS_CONFIG = 'studies/{studyId}/invitations/configs',
  GET_STUDY_INVITATION_DETAILS = 'studies/{studyId}/invitations/{invitationId}',
  POST_STUDY_INVITATION = 'studies/{studyId}/invitations',
  DELETE_STUDY_INVITATION = 'studies/{studyId}/invitations/{invitationId}',
  PUT_STUDY_INVITATION = 'studies/{studyId}/invitations/{invitationId}',
  GET_USER_INVITATION = 'studies/{studyId}/users/current/invitations',
  POST_USER_INVITATION = 'studies/{studyId}/users/current/invitations',
  POST_BULK_USER_INVITATION = 'studies/{studyId}/users/current/invitations/bulk'
}

export const getStudyInvitations = (studyId: number): Promise<AxiosResponse<InvitationType[]>> => {
  const url = InvitationsUrlEnm.GET_STUDY_INVITATIONS;
  return getApi<InvitationType[]>(url, { studyId });
};

export const getStudyInvitationsConfigs = (studyId: number): Promise<AxiosResponse<InvitationConfigType[]>> => {
  const url = InvitationsUrlEnm.GET_STUDY_INVITATIONS_CONFIG;
  return getApi<InvitationConfigType[]>(url, { studyId });
};

export const getStudyInvitationDetails = (studyId: number, invitationId: number): Promise<AxiosResponse<InvitationType>> => {
  const url = InvitationsUrlEnm.GET_STUDY_INVITATION_DETAILS;
  return getApi<InvitationType>(url, { studyId, invitationId });
};

export const postStudyInvitation = (studyId: number, invitation: InvitationType): Promise<AxiosResponse<void>> => {
  const url = InvitationsUrlEnm.POST_STUDY_INVITATION;
  return postApi<void>(url, { studyId }, invitation);
};

export const deleteStudyInvitation = (studyId: number, invitationId: number): Promise<AxiosResponse<void>> => {
  const url = InvitationsUrlEnm.DELETE_STUDY_INVITATION;
  return deleteApi<void>(url, { studyId, invitationId });
};

export const putStudyInvitation = (studyId: number, invitation: InvitationType): Promise<AxiosResponse<void>> => {
  const url = InvitationsUrlEnm.DELETE_STUDY_INVITATION;
  return putApi<void>(url, { studyId, invitationId: invitation.id }, invitation);
};

export const getUserInvitations = (studyId: number): Promise<AxiosResponse<InvitationUserGet[]>> => {
  const url = InvitationsUrlEnm.GET_USER_INVITATION;
  return getApi<InvitationUserGet[]>(url, { studyId });
};

export const postUserInvitation = (studyId: number, invitation: InvitationPostUserType): Promise<AxiosResponse<void>> => {
  const url = InvitationsUrlEnm.POST_USER_INVITATION;
  return postApi<void>(url, { studyId }, invitation);
};

export const postBulkUserInvitation = (studyId: number, value: File): Promise<AxiosResponse<void | any>> => {
  const url = InvitationsUrlEnm.POST_BULK_USER_INVITATION;
  const headers = { 'Content-Type': 'application/json' };
  const formData = new FormData();
  formData.append('file', value);
  headers['Content-Type'] = 'multipart/form-data';
  return postApi<void>(url, { studyId, }, formData, headers);
};
