import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { UserRoleEnum, UserStudyType } from 'src/types/diapason/session';
import { getApiStudyLang } from '../service/study';
import { LangEnum } from '../types/diapason/common';
import { LangType } from '../types/diapason/study';
import { FullStudyType } from '../types/diapason/session';

interface StudySliceType {
  isStudyReady: boolean;
  data: FullStudyType;
}

const initialState: StudySliceType = {
  isStudyReady: false,
  data: {
    lang: {
      primary_lang: LangEnum.FR,
      additional_langs: [LangEnum.FR]
    },
    name: 'Etude sur les enfants',
    role: UserRoleEnum.RESEARCHER,
    id: 1
  }
};

const slice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    initialize(state: StudySliceType, { payload }: PayloadAction<FullStudyType>): void {
      state.isStudyReady = true;
      state.data = payload;
    },
    getLang(state: StudySliceType, action: PayloadAction<{ lang: LangType }>): void {
      const { lang } = action.payload;
      state.data.lang = lang;
      state.isStudyReady = true;
    },
    setLoading(state: StudySliceType): void {
      state.isStudyReady = false;
    }
  }
});

export const { reducer } = slice;

export const getStudyLangSlice = (studyId: number): AppThunk => async (dispatch): Promise<void> => {
  const response = await getApiStudyLang(studyId);
  dispatch(slice.actions.getLang({ lang: response.data }));
};

export const initializeStudySlice = (userStudy: UserStudyType): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setLoading());
  const response = await getApiStudyLang(userStudy.id);
  const newStudy = {
    ...userStudy,
    lang: response.data
  };

  dispatch(slice.actions.initialize(newStudy));
};
