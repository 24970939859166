export const translations = {
  translations_list_title: 'Translations',
  translation_button: 'Translate',
  total_missing_translations: 'Missing',
  total_unvalidated_translations: 'Invalidated',
  total_validated_translations: 'Complete',
  translations_total_empty: 'Nothing to translate',
  translations_loadList_error: 'Error while loading translations list',
  translations_loadDetails_error: 'Error while loading translation details',
  translated_successfully: 'Translated successfully',
  translations_label_put_success: 'The label has been successfully updated',
  translations_label_put_error: 'The label update has failed'
};
