import { AxiosResponse } from 'axios';
import { ENV } from 'src/environments/environment';
import axios from '../lib/axios';
import { interpolate } from '../utils/interpolate';

type ParamsType = Record<string, string | number>;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.status === 401 && error.path !== '/users/current'
    ) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
    return Promise.reject(error);
  }
);
export const getApi = async <T>(
  ressourceUrl: string,
  params: ParamsType = {},
  isMocked: boolean = false,
  config = {}
): Promise<AxiosResponse<T>> => {
  const formattedUrl = interpolate(ressourceUrl, params);
  const baseUrl = isMocked ? ENV.REACT_APP_BASE_URL_MOCK : ENV.REACT_APP_BASE_URL;
  const response = await axios.get<T>(`${baseUrl}/${formattedUrl}`, config);
  return response;
};

export const deleteApi = async <T>(
  ressourceUrl: string,
  params: ParamsType = {},
  isMocked: boolean = false
): Promise<AxiosResponse<T>> => {
  const formattedUrl = interpolate(ressourceUrl, params);
  const baseUrl = isMocked ? ENV.REACT_APP_BASE_URL_MOCK : ENV.REACT_APP_BASE_URL;
  const response = await axios.delete<T>(`${baseUrl}/${formattedUrl}`);
  return response;
};

export const postApi = async <T>(
  ressourceUrl: string,
  params: ParamsType = {},
  body: Object,
  headers?: Object,
  isMocked: boolean = false
): Promise<AxiosResponse<T>> => {
  const formattedUrl = interpolate(ressourceUrl, params);
  const baseUrl = isMocked ? ENV.REACT_APP_BASE_URL_MOCK : ENV.REACT_APP_BASE_URL;
  const response = await axios.post<T>(`${baseUrl}/${formattedUrl}`, body, { headers });
  return response;
};

export const putApi = async <T>(
  ressourceUrl: string,
  params: ParamsType = {},
  body: Object,
  isMocked: boolean = false
): Promise<AxiosResponse<T>> => {
  const formattedUrl = interpolate(ressourceUrl, params);
  const baseUrl = isMocked ? ENV.REACT_APP_BASE_URL_MOCK : ENV.REACT_APP_BASE_URL;
  const response = await axios.put<T>(`${baseUrl}/${formattedUrl}`, body);
  return response;
};

export const patchApi = async <T>(
  ressourceUrl: string,
  params: ParamsType = {},
  body: Object,
  isMocked: boolean = false,
  headers?: Object,
  queryParams?: Object
): Promise<AxiosResponse<T>> => {
  const formattedUrl = interpolate(ressourceUrl, params);
  const baseUrl = isMocked ? ENV.REACT_APP_BASE_URL_MOCK : ENV.REACT_APP_BASE_URL;
  const response = await axios.patch<T>(`${baseUrl}/${formattedUrl}`, body, { headers, params: queryParams });
  return response;
};
