export interface GenericOptionType<T> {
  label: string;
  value: T;
}

export interface CreatedByType {
  firstname: string,
  lastname: string,
  date: string
}

export enum LangEnum {
  FR = 'fr',
  EN = 'en',
}
