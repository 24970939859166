export const profileDef = {
  profileDef_login: 'Login bias',
  profileDef_communication: 'Contact the users',
  profileDef_identification: 'Login method',
  profileDef_intervenors_title: 'Define intervenor',
  profileDef_intervenors_noProfile: 'No intervenor profile created',
  profileDef_intervenors_addProfile: 'Add profile',
  profileDef_intervenors_manageProfile: 'Manage profiles',
  profileDef_investigators_title: 'Define investigators',
  profileDef_subjects_title: 'Define subjects',
  profileDef_form_communication_text: 'Select how to contact the users with this role.',
  profileDef_form_communication_subtext: 'Means that anyone viewing your profile will be able to see your contacts details.',
  profileDef_form_attribute_firstname: 'Firstname',
  profileDef_form_attribute_lastname: 'Lastname',
  profileDef_form_attribute_email: 'Email / Login',
  profileDef_form_attribute_phonenumber: 'Phone',
  profileDef_form_attribute_externalId: 'Case',
  profileDef_form_attribute_nickname: 'Nickname',
  profileDef_form_consent_text: 'Use the checkbox if you would like to configure a consent for participants.',
  profileDef_form_consent_subtext: 'You can select a consent method.',
  profileDef_form_consent_hasConsent: 'Consent',
  profileDef_form_consent_NoConsent: 'There is no consent. Please create your consent first !',
  profileDef_form_consent_placeholder: 'Consent method',
  profileDef_form_consent_content_placeholder: 'Consent content',
  profileDef_form_consent_button: 'Create consent',
  profileDef_form_consent_signature: 'Signature',
  profileDef_form_consent_checkbox: 'Checkbox',
  profileDef_form_can_sign_up_only_with_invitation_title: 'Free participation or invitation only',
  profileDef_form_can_sign_up_only_with_invitation_text: 'Please choose if everyone can create an account and participate in the study or if only invited users can participate in the study:',
  profileDef_form_can_sign_up_only_with_invitation_false_label: 'Open participation to all',
  profileDef_form_can_sign_up_only_with_invitation_true_label: 'Participation only by invitation',
  profileDef_form_is_site_requested_title: 'Site and centers',
  profileDef_form_is_site_requested_text: 'Please choose if you want to show the question about "Site" and "Center" to the users:',
  profileDef_form_is_site_requested_false_label: 'No',
  profileDef_form_is_site_requested_true_label: 'Yes',
  profileDef_form_is_site_requested_first_Question: 'Please define the title of the question for the sites ?',
  profileDef_form_is_site_requested_first_Question_placeholder: 'In which region are you living in ?',
  profileDef_form_is_site_requested_second_Question: 'Please define the title of the question for the centers ?',
  profileDef_form_is_site_requested_second_Question_placeholder: 'In which department are you living in ?',
  profileDef_form_is_direct_inclusion_authorized_title: 'Direct inclusion',
  profileDef_form_is_direct_inclusion_authorized_text: 'Please choose if you want to allow the users to directly include themselves in the study:',
  profileDef_form_is_direct_inclusion_authorized_false_label: 'No',
  profileDef_form_is_direct_inclusion_authorized_true_label: 'Yes',
  profileDef_form_is_vocalization_and_recognition_authorized_title: 'Access to vocalization and speech recognition',
  profileDef_form_is_vocalization_and_recognition_authorized_text: 'Please choose if you want to allow the users to use vocalization and recognition:',
  profileDef_form_is_speech_recognition_enabled_label: 'Speech recognition',
  profileDef_form_is_vocalisation_enabled_label: 'Vocalization',
  profileDef_form_identification: 'Identify the user',
  profileDef_form_identification_text: 'Select how to identify the user with this role.',
  profileDef_form_identification_subtext: 'Means that anyone viewing your profile will be able to see your details.',
  profileDef_form_login_text: 'How to login into the application. The secret questions login method will allow you to create secret questions.',
  profileDef_form_login_subtext: 'Changes the login page for the application.',
  profileDef_form_identification_placeholder: 'Identification method',
  profileDef_form_identification_email: 'Login / Password',
  profileDef_form_identification_nickname: 'Nickname / Password',
  profileDef_form_identification_file: 'Case number',
  profileDef_form_without_identification: 'No identification',
  profileDef_form_identification_secretQuestions: 'Secret questions',
  profileDef_form_inclusion_text: 'Use the checkbox if you would like to create inclusion questions for participants.',
  profileDef_form_inclusion_hasInclusion: 'Inclusion',
  profileDef_form_inclusionQuestions_title: 'Manage inclusion questions',
  profileDef_form_inclusionQuestions_add: 'Add question',
  profileDef_form_inclusionQuestions_empty: 'No question in list',
  profileDef_form_inclusionQuestions_drawer_title_edit: 'Edit question',
  profileDef_form_inclusionQuestions_drawer_title_create: 'Create question',
  profileDef_form_inclusionQuestions_form_title: 'Title',
  profileDef_form_inclusionQuestions_form_value: 'Correct answer',
  profileDef_form_inclusionQuestions_form_export_code: 'Export Code',
  profileDef_form_inclusionQuestions_error: 'Inclusion questions update has failed',
  profileDef_form_secretQuestions_title: 'Manage secret questions',
  profileDef_form_secretQuestions_add: 'Add question',
  profileDef_form_secretQuestions_empty: 'No question in list',
  profileDef_form_secretQuestions_drawer_title_edit: 'Edit question',
  profileDef_form_secretQuestions_drawer_title_create: 'Create question',
  profileDef_form_secretQuestions_form_title: 'Title',
  profileDef_form_profile_text: 'Create new profile here. Enter a name and click the button to start managing a profile',
  profileDef_form_profile_placeholder: 'Profile name',
  profileDef_form_profile_deleteConfirm_text: 'Are you sure to delete this profile ? This action can\'t be undone',
  profileDef_form_profile_deleteConfirm_title: 'Delete profile',
  profileDef_loadDefinition_error: 'Error while loading profile definition',
  profileDef_postSecretQuestion_success: 'The secret question has been correctly created',
  profileDef_postSecretQuestion_error: 'Error during secret question creation',
  profileDef_deleteSecretQuestion_success: 'The secret question has been correctly deleted',
  profileDef_deleteSecretQuestion_error: 'Error during secret question deletion',
  profileDef_patchSecretQuestion_success: 'The secret question has been correctly updated',
  profileDef_patchSecretQuestion_error: 'Error during secret question update',
  profileDef_postInclusionQuestion_success: 'The inclusion question has been correctly created',
  profileDef_postInclusionQuestion_error: 'Error during inclusion question creation',
  profileDef_deleteInclusionQuestion_success: 'The inclusion question has been correctly deleted',
  profileDef_deleteInclusionQuestion_error: 'Error during inclusion question deletion',
  profileDef_patchInclusionQuestion_success: 'The inclusion question has been correctly updated',
  profileDef_patchInclusionQuestion_error: 'Error during inclusion question update',
  profile_creation_success: 'The profile has been correctly created',
  profile_creation_error: 'Error during profile creation',
  profile_update_success: 'The profile has been correctly updated',
  profile_update_error: 'Error during profile update',
  profile_delete_success: 'The profile has been correctly deleted',
  profile_delete_error: 'Error during profile delete',
  profile_title: 'Profile',
  profile_old_password: 'Old Password',
  profile_new_password: 'New Password',
  profile_confirm_new_password: 'Confirm New Password',
  profile_update_button: 'Update Password',
  profileDef_form_add_question: 'Add question',
  profileDef_intervenor_profile: 'Profile name',
  profileDef_intervenors_create_title: 'New intervenor',
  profileDef_form_inclusion_deleteConfirm_title: 'Delete inclusion question',
  profileDef_form_inclusion_deleteConfirm_text: 'Delete this question ? This action can\'t be undone',

  // Ajout Synakene
  profileDef_form_eligibility_text: 'Use the checkbox if you would like to create eligibility questions for participants.'
};
