import { AxiosResponse } from 'axios';
import { DiapasonUserType, LoginResponse, ResetPassType, UpdatePassType, UpdateLang } from 'src/types/diapason/session';
import { deleteApi, getApi, patchApi, postApi } from './diapasonApi';

enum SessionUrlsEnum {
  LOGIN = 'diapason/sessions',
  LOGOUT = 'diapason/sessions',
  GET_CURRENT_USER = 'users/current',
  FORGOTTEN_PASS = 'users/forgotten_password',
  RESET_PASS = 'users/reset_password',
  UPDATE_PASS = 'password',
  UPDATE_LANG = 'users/current'
}

export const loginApi = (login: string, password: string): Promise<AxiosResponse<LoginResponse>> => {
  const url = SessionUrlsEnum.LOGIN;
  return postApi<LoginResponse>(url, {}, { login, password });
};

export const logoutApi = (): Promise<AxiosResponse<LoginResponse>> => {
  const url = SessionUrlsEnum.LOGOUT;
  return deleteApi<LoginResponse>(url, {});
};

export const getCurrentUser = (): Promise<AxiosResponse<DiapasonUserType>> => {
  const url = SessionUrlsEnum.GET_CURRENT_USER;
  return getApi<DiapasonUserType>(url, {});
};

export const forgottenPasswordApi = (login: string): Promise<AxiosResponse<LoginResponse>> => {
  const url = SessionUrlsEnum.FORGOTTEN_PASS;
  return postApi(url, {}, { login });
};

export const resetPasswordApi = (resetPassBody: ResetPassType): Promise<AxiosResponse<LoginResponse>> => {
  const url = SessionUrlsEnum.RESET_PASS;
  return postApi(url, {}, resetPassBody);
};

export const updatePasswordApi = (resetPassBody: UpdatePassType): Promise<AxiosResponse<LoginResponse>> => {
  const url = SessionUrlsEnum.UPDATE_PASS;
  return patchApi(url, {}, resetPassBody);
};

export const updateLangApi = (resetLangBody: UpdateLang): Promise<AxiosResponse<LoginResponse>> => {
  const url = SessionUrlsEnum.UPDATE_LANG;
  return patchApi<LoginResponse>(url, {}, resetLangBody);
};
