import { Box, Card, CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/store';
import DeleteConfirm from '../../atoms/DeleteConfirm';
import UploadFile from '../../molecules/PopUp/UploadFile';
import RandomizationArmSlotsList from '../../organisms/Randomization/RandomizationArmSlotsList';
import DiapasonPage from '../../templates/DiapasonPage';
import useRandomizationSlotsApi from './useRandomizationSlotsApi';

const LoaderContainer = ({ text }: { text: string }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <CircularProgress color="primary" />
    <Typography variant="subtitle2" color="textPrimary" style={{ marginTop: '10px' }}>
      {text}
    </Typography>
  </div>
);

const RandomizationSlots = () => {
  const { t } = useTranslation();
  const { study } = useSelector((state) => state);
  const { slots, endpoint, postRandomizationArmSlots, loadingImport, resetRandomizationArmSlots } = useRandomizationSlotsApi(
    study.data.id,
  );
  const title = t('Randomization_Reinitialization_title');
  const text = t('Randomization_Reinitialization_text');
  const loadingText = t('Randomization_import_running');
  return (
    <DiapasonPage
      title={t('randomization_slots_list_title')}
      titleActions={(
        <Box style={{ justifyContent: 'right' }}>
          {loadingImport && <LoaderContainer text={loadingText} /> }
          {!loadingImport && (
          <>
            <UploadFile
              title={t('randomization_slots_list_upload_subtitle')}
              text={t('randomization_slots_list_upload_description')}
              button_label={t('randomization_slots_list_upload_button')}
              onClick={postRandomizationArmSlots}
              endpoint={endpoint}
            />
            <DeleteConfirm
              onConfirm={resetRandomizationArmSlots}
              buttonText={title}
              text={text}
              title={title}
            />
          </>
          )}
        </Box>
  )}
    >
      <Card sx={{ p: 1 }} style={{ overflowX: 'auto' }}>
        <RandomizationArmSlotsList slots={slots} />
      </Card>

    </DiapasonPage>
  );
};

export default RandomizationSlots;
