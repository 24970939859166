export const exportData = {
  export_data_title: 'Data Export',
  export_data_content: 'By clicking on this button, you can download the anonymized user data. You should unzip the downloaded file to access the user data files in CSV format.',
  export_data_button: 'Data Export',
  export_dictionary_title: 'Variable Dictionary',
  export_dictionary_content: 'Variable dictionary allows you to decode the variable names used in the exported data. By clicking on this button, you can download the variable dictionary.',
  export_dictionary_button: 'Data Dictionary',
  export_dictionary_success: 'Dictionary exported successfully.',
  export_data_success: 'Data exported successfully.',
  export_data_error: 'Data export failed.',
  export_dictionary_error: 'Dictionary export failed.',
  export_Journal_title: 'Journal',
  export_Journal_content: 'By clicking on this button, you can download the journal of the study.',
  export_Journal_button: 'Journal',
  export_journal_success: 'Journal exported successfully.',
  export_journal_error: 'Journal export failed.',
};
