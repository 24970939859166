export const exportData = {
  export_data_title: 'Export de données',
  export_data_content: 'En cliquant sur ce bouton, vous pouvez télécharger les données utilisateur anonymisées. Vous devez décompresser le fichier téléchargé pour accéder aux fichiers de données utilisateur au format CSV.',
  export_data_button: 'Exporter les données',
  export_dictionary_title: 'Dictionnaire de variables',
  export_dictionary_content: 'Le dictionnaire de variables vous permet de décoder les noms de variables utilisés dans les données exportées. En cliquant sur ce bouton, vous pouvez télécharger le dictionnaire des variables.',
  export_dictionary_button: 'Dictionnaire de données',
  export_dictionary_success: 'Dictionnaire de donnée exporté avec succès.',
  export_data_success: 'Données exportées avec succès.',
  export_data_error: "Erreur lors de l'export de données.",
  export_dictionary_error: "Erreur lors de l'export du dictionnaire.",
  export_Journal_title: 'Journal',
  export_Journal_content: 'En cliquant sur ce bouton, vous pouvez télécharger le journal de l\'étude.',
  export_Journal_button: 'Journal',
  export_journal_success: 'Journal exporté avec succès.',
  export_journal_error: "Erreur lors de l'export du journal.",
};
