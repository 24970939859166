export const home = {
  home_title: 'Home page',
  graph_pco1_active_legend: 'Active (Started answering)',
  graph_pco1_title: 'Inclusions\'s track',
  graph_poc1_subtitle: 'subject status by study',
  graph_poc1_legend_title: 'Legend',
  graph_poc1_xaxis: 'Inclusion Date',
  graph_poc1_yaxis: 'Number',
  graph_poc2_xaxis: 'Inclusion Date',
  graph_poc2_yaxis: 'Number',
  graph_poc2_title: 'Number of active patients per sequences',
  graph_poc2_subtitle: 'tracking patients by sequences',
  graph_poc2_legend_title: 'Legend',
  graph_poc3_title: 'Number of patients by status of sequence 1',
  home_coding_title: 'No need for coding',
  home_coding_content: 'Create your accessible application without coding, through an easy-to-use environment.',
  home_questionnaire_title: 'Questionnaire',
  home_questionnaire_content: 'Create questionnaires with multiple types of questions. Personalize your study with graphics, image, and typography, video.',
  home_invitation_title: 'Invitation & Reminder',
  home_invitation_content: 'Invite your users through Email, SMS or public link, and remind them to participate in the study with mobile notifications.',
  graph_pco4_title: 'Recruitment tracking',
  graph_poc4_subtitle: 'number of subjects by study',
  graph_pco5_title: 'Patient follow-up in the study',
  graph_poc5_subtitle: 'sequences status'
};
