export const randomization = {
  randomization_title: 'Randomisation',
  randomization_arms: 'Bras de randomisation',
  randomization_arms_create_button: 'Créer bras de randomisation',
  randomization_config_value_CLUSTER: 'Randomisation par cluster',
  randomization_config_value_INDIVIDUAL: 'Randomisation individuelle',
  randomization_config_method_AUTO: 'Automatique',
  randomization_config_method_FILE: 'Import d\'un fichier externe',
  randomization_stratification_title: 'Stratification',
  randomization_stratification_create_button: 'Créer questions de stratification',
  randomization_stratification_create_title: 'Créer questions de stratification',
  randomization_arms_deleteConfirm_title: 'Supprimer une randomization',
  randomization_arms_deleteConfirm_text: 'Supprimer cet élément ? L\'action ne peut pas être annulée.',
  randomization_stratification_deleteConfirm_text: 'Supprimer cet la stratification ?. L\'action ne peut pas être annulée.',
  randomization_stratification_deleteConfirm_title: 'Supprimer une stratification',
  randomization_choice: 'Choix de la randomisation',
  randomization_method: 'Moyen de randomisation',
  randomization_arms_title_edit: 'Editer bras de randomisation',
  randomization_arms_title_create: 'Créer bras de randomisation',
  randomization_create_success: 'Randomisation correctement créée',
  randomization_create_error: 'Erreur lors de la création de la randomization',
  randomization_description_first_title: 'Randomization',
  randomization_description_second_title: 'Stratification',
  randomization_description_third_title: 'Table de randomization',
  randomization_description_fourth_title: "Importation d'une table de randomisation",
  randomization_description_first_content: "Dans la page suivante, vous pouvez choisir d'utiliser la randomisation dans votre étude. Ensuite, vous pouvez créer plusieurs bras de randomisation.",
  randomization_description_second_content: 'Dans la page suivante, vous pouvez également créer des questions de stratification, qui sont des questions à choix unique. Ces questions seront posées à partir des sujets de votre candidature.',
  randomization_description_third_content: 'Après avoir créé des bras de randomisation et peut-être des questions de stratification, un tableau sera généré qui illustre toutes les combinaisons strat-bras possibles (cellules du tableau). Chaque strat (lignes du tableau) a un code créé par la plateforme, et chaque arm (colonnes du tableau) a un nom défini par vous. Chaque strat-arm contient le nombre de slots utilisés (utilisateurs inclus dans le strat-arm) et le nombre total de slots pour le strat-arm.',
  randomization_description_fourth_content: "Vous pouvez définir l'ordre dans lequel les utilisateurs seront affectés aux strat-arms et le nombre total d'emplacements disponibles pour chaque strat-arm en important votre fichier de randomisation. Le fichier csv doit avoir la structure suivante : code;arm (exemple : 1;RANDO_A)",
  randomization_slots_list_title: 'Liste des emplacements disponibles',
  randomization_slots_list_createButton: 'Ajouter des emplacements',
  randomization_slots_list_upload_subtitle: 'Importer un fichier de randomisation',
  Randomization_Reinitialization_title: 'Réinitialiser les slots de randomisation',
  Randomization_Reinitialization_text: 'Êtes-vous sûr de réinitialiser les slots de randomisation ?',
  Randomization_import_running: 'Importation en cours. L\'import peut prendre du temps, merci de ne pas fermer la page.',
  randomization_slots_list_upload_description: 'Vous pouvez importer un fichier csv contenant les emplacements de randomisation. Le fichier doit avoir la structure suivante : code;arm (exemple : 1;RANDO_A)',
  randomization_slots_list_upload_button: 'Importer Randomisation fichier',
  randomization_slots_list_reset_button: 'Réinitialiser la randomisation des emplacements',
  randomization_slots_load_error: 'Erreur lors du chargement des emplacements de randomisation',
  randomization_slots_load_success: 'Emplacements de randomisation correctement chargés',
  randomization_slots_reset_error: 'Erreur lors de la réinitialisation des emplacements de randomisation',
  randomization_slots_reset_success: 'Emplacements de randomisation correctement réinitialisés',
  randomization_slots_created_success: 'Emplacements de randomisation correctement créés',
  randomization_slots_created_error: 'Erreur lors de la création des emplacements de randomisation',
  randomization_update_warning_header: 'Attention!',
  randomization_update_warning: 'Si vous modifiez vos bras de randomisation ou vos questions de stratification, cela aura un impact sur votre table de randomisation et vous devrez réinitialiser la table et importer un nouveau fichier de randomisation.'
};
