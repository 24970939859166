import { Box } from '@material-ui/core';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SettingsIcon from '@material-ui/icons/Settings';
import BrushIcon from '@material-ui/icons/Brush';
import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import EmailIcon from '@material-ui/icons/Email';
import ForwardIcon from '@material-ui/icons/Forward';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TranslateIcon from '@material-ui/icons/Translate';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CasinoIcon from '@material-ui/icons/Casino';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import HomeIcon from '@material-ui/icons/Home';
import { useSelector } from 'src/store';
import { UserRoleEnum } from 'src/types/diapason/session';
import NavSection from '../../atoms/NavSection';

const SidebarSections = (): ReactElement => {
  const location = useLocation();
  const { t } = useTranslation();
  const { study } = useSelector((state) => state);

  const isTranslator = study.data.role === UserRoleEnum.TRANSLATOR;
  const sections = [
    {
      title: '',
      items: [{
        title: t('home_title'),
        path: '/home-page',
        icon: <HomeIcon fontSize="small" />
      }]
    },
    {
      title: t('navBar_sections_configuration'),
      items: [
        {
          title: t('navBar_sections_profileType'),
          path: '/profiles/description',
          icon: <PeopleIcon fontSize="small" />,
          children: [
            {
              title: t('navBar_sections_investigators'),
              path: '/definition/investigators'
            },
            {
              title: t('navBar_sections_intervenors'),
              path: '/definition/intervenors'
            },
            {
              title: t('navBar_sections_subjects'),
              path: '/definition/subjects'
            }
          ]
        },
        {
          title: t('navBar_sections_contents'),
          path: '/contents/description',
          icon: <BrushIcon fontSize="small" />,
          children: [
            {
              title: t('navBar_sections_study_presentations'),
              path: '/contentType/study-presentations'
            },
            {
              title: t('navBar_sections_consent'),
              path: '/contentType/consent'
            },
            {
              title: t('navBar_sections_sequence_content'),
              path: '/contentType/sequence-content'
            },
          ],

        },
        {
          title: t('navBar_sections_questionnaires'),
          path: '/questionnaires',
          icon: <FileCopyIcon fontSize="small" />
        },
        {
          title: t('navBar_sections_sequences'),
          path: '/sequences/description',
          icon: <EventAvailableIcon fontSize="small" />
        },
        {
          title: t('navBar_sections_sitesLabs'),
          path: '/sites',
          icon: <ApartmentIcon fontSize="small" />
        },
        {
          title: t('navBar_sections_invitations'),
          path: '/invitations',
          icon: <EmailIcon fontSize="small" />
        },
        {
          title: t('navBar_sections_randomization'),
          path: '/randomization_description',
          icon: <CasinoIcon fontSize="small" />,
          children: [
            {
              title: t('navBar_sections_randomization_settings'),
              path: '/randomization/settings',
              icon: <CasinoIcon fontSize="small" />
            },
            {
              title: t('navBar_sections_randomization_slots'),
              path: '/randomization/slots',
              icon: <CasinoIcon fontSize="small" />
            },
          ]
        },
        {
          title: t('navBar_sections_translations'),
          path: '/translations',
          icon: <TranslateIcon fontSize="small" />
        },
        {
          title: t('navBar_sections_final_params'),
          path: '/final-params',
          icon: <SettingsIcon fontSize="small" />
        }
      ]
    },
    {
      title: t('navBar_sections_testDeploy'),
      items: [
        {
          title: t('navBar_sections_testMode'),
          path: '/deployment',
          icon: <DesktopMacIcon fontSize="small" />,
        },
      ]
    },
    {
      title: t('navBar_sections_study_impl'),
      items: [
        {
          title: t('navBar_sections_invitation_investigators'),
          path: '/invitation-investigators',
          icon: <ForwardIcon fontSize="small" />
        },
        {
          title: t('navBar_sections_massCommunication'),
          path: '/mass-communication',
          icon: <ForwardIcon fontSize="small" />
        },
        {
          title: t('navBar_sections_dashboards'),
          path: '/dashboard',
          icon: <InsertChartIcon fontSize="small" />
        },
        {
          title: t('navBar_sections_exports'),
          path: '/exports',
          icon: <StorageRoundedIcon fontSize="small" />
        }]
    }
  ];

  const translator_sections = [
    {
      title: '',
      items: [{
        title: t('home_title'),
        path: '/home-page',
        icon: <HomeIcon fontSize="small" />
      },
      {
        title: t('navBar_sections_translations'),
        path: '/translations',
        icon: <TranslateIcon fontSize="small" />
      }
      ]
    },
  ];

  return (
    <Box sx={{ p: 2 }} className="bg-dark bg-opacity-20">
      {(isTranslator ? translator_sections : sections).map((section) => (
        <NavSection
          key={section.title}
          pathname={location.pathname}
          {...section}
        />
      ))}
    </Box>
  );
};

export default SidebarSections;
