export const deployment = {
  deployment_first_card_title: 'Web Deployment',
  deployment_first_card_content: 'You can access the web version of your application via the link below:',
  deployment_first_card_button_text: 'Deploy',
  deployment_second_card_title: 'Android Deployment',
  deployment_second_card_content: 'Android deployment requires several manual actions. The administrator of Kastafiore can help you in this process. By clicking this button, an email will be sent to the admin of Kastafiore for helping with Android deployment.',
  deployment_second_card_button_text: 'Ask Admin',
  deployment_third_card_title: 'Delete Test Data',
  deployment_third_card_content: 'By clicking this button, all the collected data from users will be marked as test data. This can help you distinguish between real users and test users after the data export.',
  deployment_third_card_button_text: 'Reset Data',
};
