import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserInvitations, postBulkUserInvitation, postUserInvitation } from 'src/service/invitations';
import { useSelector } from 'src/store';
import { InvitationUserType } from 'src/types/diapason/study';

interface useInvitationsUserApiHookReturn {
  invitationsUser: InvitationUserType[] | null;
  loadInvitationsUser: () => Promise<void>;
  putInvitationUser: (invitationUserData: InvitationUserType) => Promise<void>;
  postBulkInvitationUser: (file: File) => Promise<void>
}

export const exportFile = (data:any) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'error-file.csv';
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};

const useInvitationsUserApi = (): useInvitationsUserApiHookReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { study } = useSelector((state) => state);
  const [invitationsUser, setInvitationsUser] = useState<any[] | null>(null);

  const loadInvitationsUser = async (): Promise<void> => {
    try {
      const { data }: any = await getUserInvitations(study.data.id);
      setInvitationsUser(data);
    } catch (e) {
      enqueueSnackbar(`${t('invitation_user_load_error')}: ${e.message} `, {
        variant: 'error',
      });
    }
  };

  const putInvitationUser = async (invitationUserData: InvitationUserType): Promise<void> => {
    try {
      const { channel, invitation_config_id, lang, ...target_user } = invitationUserData;
      await postUserInvitation(study.data.id, {
        channel,
        invitation_config_id,
        lang,
        target_user
      });
      loadInvitationsUser();
      enqueueSnackbar(t('invitation_user_load_create_success'), {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(`${t('invitation_user_load_create_error')}: ${e.message} `, {
        variant: 'error',
      });
      throw e;
    }
  };

  const postBulkInvitationUser = async (file: File): Promise<void> => {
    try {
      const result = await postBulkUserInvitation(study.data.id, file);
      if (result.data) exportFile(result.data);
      enqueueSnackbar(t('invitations_user_bulk_create_success'), {
        variant: 'success',
      });
      loadInvitationsUser();
    } catch (e) {
      enqueueSnackbar(`${t('invitations_user_bulk_create_error')}: ${e.message} `, {
        variant: 'error',
      });
      throw e;
    }
  };

  return {
    invitationsUser,
    loadInvitationsUser,
    putInvitationUser,
    postBulkInvitationUser,
  };
};

export default useInvitationsUserApi;
