export const invitation = {
  invitations_list_title: 'Invitations List',
  invitations_list_createButton: 'New invitation',
  invitations_list_deleteConfirm_title: 'Delete invitation',
  invitations_list_deleteConfirm_text: 'Delete this invitation ?',
  invitation_channel_EMAIL: 'Email',
  invitation_channel_SMS: 'SMS',
  invitation_channel_NOTIFICATION: 'Notification',
  invitation_details_notification_content: 'Notification content',
  invitation_details_notification_title: 'Title',
  invitation_channel_PUBLICLINK: 'Public link',
  invitation_channel: 'Channel',
  invitation_denomination: 'Denomination',
  invitation_details_sms_content: 'SMS content',
  invitation_details_sms_hello: 'Hello',
  invitation_details_sms_body: 'Message',
  invitation_details_sms_signature: 'Signature',
  invitation_details_email_content: 'Mail content',
  invitation_details_email_object: 'Object',
  invitation_details_email_title: 'Mail subject',
  invitation_details_link_content: 'Link content',
  invitation_details_link_text: 'Here you can manage your invitations by link',
  invitation_details_link_copy: 'Copy link',
  invitation_details_link_copy_valid: 'Copied to clipboard!',
  invitation_details_link_label: 'Public link',
  invitation_details_recipient_from: 'From',
  invitation_details_recipient_to: 'To',
  invitation_details_preview: 'Preview',
  invitation_denomination_NONE: 'None',
  invitation_denomination_FIRSTNAME: 'Firstname',
  invitation_channel_LASTNAME: 'Lastname',
  invitation_channel_LASTNAME_FIRSTNAME: 'Lastname - Firstname',
  invitation_channel_FIRSTNAME_LASTNAME: 'Firstname - Lastname',
  invitation_signature: 'Signature',
  invitation_method: 'Firming method',
  invitation_method_select: 'Select your signature method',
  invitation_signature_code_NONE: 'No signature',
  invitation_signature_code_NAME: 'Use Name',
  invitation_signature_code_LABEL: 'Custom text',
  invitation_title: 'Invitation details',
  invitation_create_title: 'Invitation creation',
  invitation_details_backToList: 'Back to invitation list',
  invitation_details_recipient: 'Recipient',
  invitation_preview_title: 'Preview',
  invitation_preview_signature_name: 'Your name',
  invitations_load_error: 'Error while loading invitation list',
  invitations_config_load_error: 'Error while loading invitations configuration',
  invitations_details_load_error: 'Error while loading invitation details',
  invitations_details_put_success: 'Invitation correctly saved',
  invitations_details_put_error: 'Error while saving invitation details',
  invitations_delete_success: 'Invitation correctly removed',
  invitations_delete_error: 'Error while removing invitation',
  invitation_post_success: 'Invitation correctly created',
  invitation_post_error: 'Error while creating invitation',
  invitation_translation_title: 'Invitations translations',
  invitation_text: 'Be careful, channel depends on sender and recipient choice',
  invitation_body_ps_text: 'is a placeholder for the invitation link that will be replaced by the real link when the invitation is sent.',
};
