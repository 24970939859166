import { MenuItem, Typography } from '@material-ui/core';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UserStudyType } from 'src/types/diapason/session';
import LabelSelect from '../../atoms/LabelSelect';

interface StudySelectorProps {
  currentStudy: UserStudyType;
  availableStudies: UserStudyType[];
  onStudyChange: (studyId: number) => void;
}

const StudySelector = ({ currentStudy, availableStudies, onStudyChange }: StudySelectorProps): ReactElement => {
  const { t } = useTranslation();
  const options = availableStudies.map(({ id, name, role }: UserStudyType): ReactElement => (
    <MenuItem
      value={id}
      key={id}
    >
      <Typography variant="body2">{`${id}: ${name} (${role})`}</Typography>
    </MenuItem>
  ));

  return (
    <LabelSelect
      disabled={availableStudies.length === 0}
      value={currentStudy.id}
      onChange={(newId: string): void => onStudyChange(Number(newId))}
      placeholder={t('common_study')}
      options={options}
      color="white"
    />
  );
};

export default StudySelector;
