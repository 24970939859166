import { QuestionnaireStatusEnum } from './types/diapason/questionnaire';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

// TODO: should be deleted ?
export const backgroundColorByStatus: Record<QuestionnaireStatusEnum, string> = {
  inactive: 'rgb(96, 125, 139)',
  active: 'rgb(33, 150, 243)',
  error: 'rgb(255, 193, 7)'
};

// TODO: should be deleted ?
export const colorByStatus: Record<QuestionnaireStatusEnum, string> = {
  inactive: 'white',
  active: 'white',
  error: 'black'
};

export function Noop() {}

export const darkBlueColor = '#041723';
export const primaryColor = '#F2B994';
