import { Box, Toolbar } from '@material-ui/core';
import { ReactElement } from 'react';
import LanguagePopover from '../../molecules/Navbar/LanguagePopover';

const LoginHeader = (): ReactElement => (
  <Toolbar sx={{ minHeight: 64, display: 'flex', justifyContent: 'flex-end' }}>
    <Box>
      <LanguagePopover />
    </Box>
  </Toolbar>
);

export default LoginHeader;
