export const sequence = {
  sequence_list_title: 'Liste des séquences',
  sequence_list_createButton: 'Créer séquence',
  sequence_create_title: 'Créer une séquence',
  sequence_create_subtitle: 'Pour créer une séquence merci de remplir le formulaire',
  sequence_numberDisplayed: '{{number}} Résultat',
  sequence_destinataire: 'Destinataires',
  sequence_delay: 'Délai de début',
  sequence_delay_required: 'Délai de début est requis !',
  sequence_delay_suffix: 'jours après inclusion du sujet',
  sequence_duration: 'Durée',
  sequence_duration_suffix: 'jours',
  sequence_random_arms: 'Bras de randomisation',
  sequence_card_button: 'Modifier',
  sequence_searchPlaceholder: 'Chercher par titre',
  sequence_details_title: 'Séquence',
  sequence_details_goBack: 'Retour à la séquence',
  sequence_details_rolesInput: 'Rôles',
  sequence_details_armsInput: 'Bras de randomisation',
  sequence_details_delayInput: 'Délai de début',
  sequence_details_durationInput: 'Durée',
  sequence_details_infos_title: 'Informations de la séquence',
  sequence_details_content_add: 'Ajouter contenu',
  sequence_details_content_popover_title: 'Sélectionner un contenu',
  sequence_details_content_placeholder: 'Ajouter contenu',
  sequence_details_questionnaire_add: 'Ajouter questionnaire',
  sequence_details_questionnaire_popover_title: 'Sélectionner un questionnaire',
  sequence_details_questionnaire_placeholder: 'Questionnaires',
  sequence_details_components_title: 'Composants',
  sequence_loadList_error: 'Erreur durant le chargement de la liste des séquences',
  sequence_loadDetails_error: 'Erreur lors du chargement des détails de la séquence',
  sequence_create_success: 'Séquence créée avec succès',
  sequence_create_error: 'Erreur lors de la création de la séquence',
  sequence_update_error: 'Erreur lors de la mise à jour de la séquence',
  sequence_update_success: 'La mise à jour de la séquence a été effectuée avec succès',
  sequence_delete_success: 'La séquence a bien été supprimée',
  sequence_delete_error: 'Erreur lors de la suppression de la séquence',
  sequence_list_deleteConfirm_title: 'Surpression d\'une séquence',
  sequence_list_deleteConfirm_text: 'Êtes-vous sur de vouloir supprimer cette séquence ? Cette action ne peut pas être annulée',
  sequence_details_questionnaire_delete_title: 'Retirer un questionnaire',
  sequence_details_questionnaire_delete_text: 'Êtes-vous sur de vouloir retirer ce questionnaire de la séquence ?',
  sequence_details_content_delete_title: 'Retirer un contenu',
  sequence_details_content_delete_text: 'Êtes-vous sur de vouloir retirer ce contenu de la séquence ?',
  sequence_component_create_success: 'Contenu ajouté avec succès',
  sequence_component_create_error: 'Erreur durant la création du contenu',
  sequence_component_delete_success: 'Contenu retiré avec succès',
  sequence_component_delete_error: 'Erreur durant l’enlèvement du contenu',
  content_translation_title: 'Traductions des contenus',
  sequence_reminders: 'Relances',
  sequence_reminders_text: 'Les relances serviront à envoyer aux sujets / intervenants / investigateurs pour compléter leurs séquences',
  sequence_title: 'Titre de la séquence',
  sequence_welcome: 'Message de bienvenue',
  sequence_translation_title: 'Traductions des séquences',
  sequence_order_error: 'Erreur lors de la mise à jour de l\'ordre des composants',
  sequence_details_reminder_add: 'Ajouter Relance',
  sequence_reminder_title: 'Relance',
  sequence_reminder_put_success: 'Relance correctement sauvegardée',
  sequence_reminder_put_error: 'Erreur durant la sauvegarde de la relance',
  sequence_reminder_delete_success: 'Relance supprimée',
  sequence_reminder_delete_error: 'Erreur lors de la suppression de la relance',
  sequence_reminder_post_success: 'Relance créée',
  sequence_reminder_post_error: 'Erreur lors de la création de la relance',
  sequence_reminder_txt: 'La durée de la séquence correspondante est de ',
  sequence_reminder_create_title: 'Créer une relance',
  reminders_list_deleteConfirm_title: 'Supprimer relance',
  reminders_list_deleteConfirm_text: 'Supprimer cette relance ?',
  sequence_description_first_title: 'Contenu & Questionnaire',
  sequence_description_second_title: 'Programme',
  sequence_description_third_title: 'Rappels',
  sequence_description_fourth_title: 'Sequence',
  sequence_description_first_content: 'Une séquence peut contenir un(des) questionnaire(s) et un(des) contenu(s). Vous pouvez choisir des contenus et des questionnaires que vous avez déjà créés dans les sections Questionnaire et Contenu de cette plateforme. Vous pouvez également définir leur ordre en les faisant glisser de haut en bas.',
  sequence_description_second_content: "Pour chaque séquence, vous pouvez définir un horaire. Le calendrier est établi en fonction de la date d'inclusion de l'utilisateur. Par exemple, vous pouvez paramétrer votre deuxième séquence pour qu'elle s'affiche à l'utilisateur, x jours après son inclusion.",
  sequence_description_third_content: 'Vous pouvez créer des rappels pour les séquences. Un rappel peut être envoyé par SMS, e-mail et notification mobile. Vous devez configurer le contenu et choisir le canal de votre rappel, ainsi que définir un calendrier pour votre rappel en fonction de la date de début de la séquence.',
  sequence_description_fourth_content: "Dans la page suivante, vous pouvez créer une séquence d'étude. Certaines études telles que les recherches longitudinales peuvent inclure plusieurs étapes ou étapes à différents intervalles de temps. Ici, ces étapes d'étude sont appelées séquences."
};
