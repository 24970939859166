import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import { updateLangApi } from 'src/service/session';

export interface changeUserLangHookType {
  activeLang: string;
  UpdateLang: (language: string) => Promise<void>;
}

const useUpdateUserLang = (): changeUserLangHookType => {
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const [activeLang, setActiveLang] = useState<string>(user?.lang ?? i18n.language);

  useEffect(() => {
    if (user?.lang) {
      i18n.changeLanguage(user.lang);
    }
  }, [user]);

  const UpdateLang = async (language: string): Promise<void> => {
    i18n.changeLanguage(language);
    setActiveLang(language);
    if (user) {
      await updateLangApi({
        id: user?.id,
        lang: language,
      });
    }
  };
  return {
    activeLang,
    UpdateLang
  };
};
export default useUpdateUserLang;
