export const contents = {
  contents_title: 'Gestion des contenus',
  contents_list_title: 'Liste des contenus',
  contents_list_createButton: 'Créer un contenu',
  contents_list_deleteConfirm_title: 'Suprression d\'un contenu',
  contents_list_deleteConfirm_text: 'Êtes-vous sur de vouloir supprimer ce contenu ? Cette action ne peut pas être annulé',
  contents_form_title: 'Création d\'un contenu',
  contents_form_subtitle: 'Saisissez les information du contenu',
  contents_form_submit: 'Sauvegarder',
  contents_search_placeholder: 'Rechercher par label',
  contents_details_backToList: 'Retour à la liste des contenus',
  contents_loadList_error: 'Erreur lors du chargement de la liste des contenus',
  contents_delete_success: 'Le contenu a bien été supprimé',
  contents_delete_error: 'Erreur lors de la suppression du contenu',
  contents_create_success: 'Contenu créé avec succès',
  contents_create_error: 'Erreur lors de la crétion du contenu',
  contents_details_load_error: 'Erreur lors du chargement du contenu',
  contents_details_put_success: 'Le contenu a correctement été mis à jour',
  contents_details_put_error: 'Erreur lors de la mise à jour du contenu',
  contents_details_uploadFile_success: 'Le fichier a bien été enregistré',
  contents_details_uploadFile_error: 'Erreur lors de la sauvegarde du fichier',
  content_type_CONSENT: 'Consentement',
  content_type_HOMESTUDY: 'Accueil de l\'étude',
  content_type_ONBOARDINGSTUDY: 'Embarquement dans l\'étude',
  content_type_QUESTIONNAIRE: 'Contenu de séquence',
  content_type_SEQBEGIN: 'Début de séquence',
  content_type_SEQEND: 'Fin de séquence',
  content_type_INELIGIBLEINCLUSION: 'Page d\'inéligibilité',
  content_type_DETAILEDHOMESTUDY: 'Page de détail',
  content_type_LOGGUEDHOMESTUDY: 'Page principale',
  content_description_first_title: "Présentation de l'étude",
  content_description_second_title: 'Consentement',
  content_description_third_title: 'Contenue de la séquence',
  content_description_first_content: "Dans les pages suivantes, vous pouvez présenter votre étude aux utilisateurs de votre application. Vous pouvez définir votre texte et le personnaliser avec la typographie disponible. Vous pouvez également ajouter des images, des tableaux et des vidéos (comme un lien vidéo YouTube) à votre présentation d'étude.",
  content_description_second_content: "Les participants à une étude de recherche sont généralement tenus de donner leur consentement. Dans les pages suivantes, vous pouvez définir le contenu de vos consentements et les affecter à un profil d'utilisateur (sujet, enquêteur, intervenant).",
  content_description_third_content: 'Dans les pages suivantes, vous pouvez également définir le ou les contenus qui peuvent être affichés avant ou après vos questionnaires, ou à différentes étapes (séquences) de votre étude.',
};
