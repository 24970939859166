export const login = {
  login_title: 'Connexion',
  login_subTitle: 'Bienvenue sur Diapason',
  login_email_label: 'Email',
  login_email_error_valid: 'Entrer un email valide',
  login_email_error_required: 'Un email est requis',
  login_password_label: 'Mot de passe',
  login_password_confirm: 'Confirmation du mot de passe',
  login_password_error_required: 'Un mot de passe est requis',
  login_button: 'Connexion',
  login_button_forgot: 'Mot de passe oublié',
  login_recoveryPassword_title: 'Récupération du mot de passe',
  login_recoveryPassword_subTitle: 'Entrez votre adresse mail pour recevoir un lien de réinitialisation de mot de passe',
  login_recoveryPassword_button: 'Récupérer le mot de passe',
  login_resetPassword_title: 'Réinitialisation du mot de passe',
  login_resetPassword_subTitle: 'Vous n\'avez pas reçu l\'email',
  login_resetPassword_button: 'Réinitialiser le mot de passe',
  login_password_error_length: 'Le mot de passe doit être d\'au moins 8 caractères',
  login_password_error_match: 'Les mots de passe doivent correspondre',
  login_err_reset_message: 'Une erreur s\'est produite',
  login_reset_password_success: 'Si votre email est connu dans nos systèmes, vous devriez recevoir rapidement un courriel avec les instructions pour réinitialiser votre mot de passe.',
  login_reset_password_error: 'Une erreur s\'est produite lors de l\'envoi de l\'e-mail',
  login_description: 'Kastafiore est une plateforme pour créer des applications de recherche accessibles sans aucune compétence en codage. Il peut créer des questionnaires, ainsi que gérer les invitations, les notifications et la traduction.',
  login_description_translator: 'Ici, vous pouvez ajouter, modifier et supprimer des traductions pour les différents composants de l\'application.',
  login_welcome_to: 'Bienvenue à'
};
