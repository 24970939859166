import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { getCurrentUser, loginApi, logoutApi } from 'src/service/session';
import { DiapasonUserType } from 'src/types/diapason/session';
import { useDispatch } from 'src/store';
import { initializeStudySlice } from 'src/slices/study';
import axios from '../lib/axios';

const TOKEN_KEY_NAME = 'diapason_access_token';
const getStudyData = JSON.parse(localStorage.getItem('studyData'));
interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: DiapasonUserType | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: DiapasonUserType | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: DiapasonUserType;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem(TOKEN_KEY_NAME, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(TOKEN_KEY_NAME);
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const studyDispatch = useDispatch();

  useEffect(() => {
    const dispatchInitialize = (user: DiapasonUserType = null): void => {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: user !== null,
          user
        }
      });
    };

    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem(TOKEN_KEY_NAME);

        if (accessToken) {
          setSession(accessToken);
          const { data: user } = await getCurrentUser();
          await studyDispatch(initializeStudySlice(getStudyData || user.studies[0]));
          dispatchInitialize(user);
        } else {
          dispatchInitialize();
        }
      } catch (err) {
        dispatchInitialize();
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const response = await loginApi(email, password);
    const { token } = response.data;
    setSession(token);

    const { data: user } = await getCurrentUser();
    studyDispatch(initializeStudySlice(getStudyData || user.studies[0]));
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async (): Promise<void> => {
    localStorage.removeItem('studyData');
    await logoutApi();
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
