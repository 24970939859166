import { useEffect } from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
} from '@material-ui/core';
import { initializeStudySlice } from 'src/slices/study';
import useAuth from '../../../../hooks/useAuth';
import Scrollbar from '../../atoms/Scrollbar';
import { useDispatch, useSelector } from '../../../../store';
import DiapasonLogo from '../../atoms/DiapasonLogo';
import SidebarSections from '../../molecules/Sidebar/SidebarSections';
import StudySelector from '../../molecules/Sidebar/StudySelector';

interface DiapasonSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DiapasonSidebar: FC<DiapasonSidebarProps> = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { study: { data: studyData } } = useSelector((state) => state);
  const { user } = useAuth();
  const studyDispatch = useDispatch();
  const handleChane = async (studyId: number) => {
    localStorage.setItem('studyData', JSON.stringify(user.studies.find((study) => (study.id === studyId))));
    await studyDispatch(initializeStudySlice(user.studies.find((study) => (study.id === studyId))));
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box sx={{ p: 2 }}>
            <DiapasonLogo textColor="text-white" />
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }} className="bg-dark bg-opacity-50">
          <StudySelector
            currentStudy={studyData}
            availableStudies={user.studies}
            onStudyChange={async (studyId: number): Promise<void> => handleChane(studyId)}
          />
        </Box>
        <Divider />
        <SidebarSections />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DiapasonSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DiapasonSidebar;
