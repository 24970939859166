export const profileDef = {
  profileDef_login: 'Méthode de connexion',
  profileDef_communication: 'Moyen de communication',
  profileDef_identification: 'Moyen de connexion',
  profileDef_intervenors_title: 'Définir les intervenants',
  profileDef_intervenors_noProfile: 'Aucun profil intervenant paramétré pour cette étude',
  profileDef_intervenors_addProfile: 'Ajouter profil',
  profileDef_intervenors_manageProfile: 'Gérer les profiles',
  profileDef_investigators_title: 'Définir les investigateurs',
  profileDef_subjects_title: 'Définir les sujets',
  profileDef_form_communication_text: 'Sélectionnez comment communiquer avec les utilisateurs ayant ce rôle.',
  profileDef_form_communication_subtext: 'Ces informations seront visibles sur votre profil.',
  profileDef_form_attribute_firstname: 'Prénom',
  profileDef_form_attribute_lastname: 'Nom',
  profileDef_form_attribute_email: 'Email / Login',
  profileDef_form_attribute_phonenumber: 'Téléphone',
  profileDef_form_attribute_externalId: 'No de Dossier',
  profileDef_form_attribute_nickname: 'Pseudonyme',
  profileDef_form_consent_text: 'Cochez la case suivante si vous voulez définir un consentement pour les participants.',
  profileDef_form_consent_subtext: 'Vous pourriez alors choisir la méthode de consentement.',
  profileDef_form_consent_hasConsent: 'Consentement',
  profileDef_form_consent_NoConsent: 'Il n\'y a pas de consentement. Veuillez d\'abord créer votre consentement !',
  profileDef_form_consent_placeholder: 'Méthode de consentement',
  profileDef_form_consent_content_placeholder: 'Contenu du consentement',
  profileDef_form_consent_button: 'Créer consentement',
  profileDef_form_consent_signature: 'Signature',
  profileDef_form_consent_checkbox: 'Case à cocher',
  profileDef_form_consent_choice: 'Choix',
  profileDef_form_can_sign_up_only_with_invitation_title: 'Participation libre ou sur invitation',
  profileDef_form_can_sign_up_only_with_invitation_text: "Veuillez choisir si tout le monde doit pouvoir créer un compte et participer à l'étude ou si seuls les utilisateurs invités peuvent participer à l'étude :",
  profileDef_form_can_sign_up_only_with_invitation_false_label: 'Une participation ouverte à tous',
  profileDef_form_can_sign_up_only_with_invitation_true_label: 'Participation uniquement sur invitation',
  profileDef_form_is_vocalization_and_recognition_authorized_title: 'Accessibilité',
  profileDef_form_is_vocalization_and_recognition_authorized_text: 'Veuillez sélectionner si vous souhaitez inclure la reconnaissance vocale et la vocalisation pour ce profil :',
  profileDef_form_is_speech_recognition_enabled_label: 'Reconnaissance vocale',
  profileDef_form_is_vocalisation_enabled_label: 'Vocalisation',
  profileDef_form_is_site_requested_title: 'Sites et centres',
  profileDef_form_is_site_requested_text: 'Veuillez choisir si vous voulez afficher la question des sites et des centres à ce profil :',
  profileDef_form_is_site_requested_false_label: 'Non',
  profileDef_form_is_site_requested_true_label: 'Oui',
  profileDef_form_is_site_requested_first_Question: 'Veuillez définir le titre de la question pour les sites ?',
  profileDef_form_is_site_requested_first_Question_placeholder: 'Dans quelle région habitez-vous ?',
  profileDef_form_is_site_requested_second_Question: 'Veuillez définir le titre de la question pour les sous - sites ?',
  profileDef_form_is_site_requested_second_Question_placeholder: 'Dans quelle ville habitez-vous ?',
  profileDef_form_is_direct_inclusion_authorized_title: 'Inclusion manuelle des sujets',
  profileDef_form_is_direct_inclusion_authorized_text: "Veuillez choisir si vous voulez permettre aux investigateurs d'ajouter des sujets directement dans leur application :",
  profileDef_form_is_direct_inclusion_authorized_false_label: 'Non',
  profileDef_form_is_direct_inclusion_authorized_true_label: 'Oui',
  profileDef_form_identification: 'Méthodes d\'identification de l\'utilisateur',
  profileDef_form_identification_text: 'Sélectionnez ce qui permettra d\'identifier les utilisateurs ayant ce rôle.',
  profileDef_form_identification_subtext: 'Ces informations seront visibles sur votre profil.',
  profileDef_form_login_text: 'Sélectionnez le moyen de connexion pour ce type de profil. Pour l\'identification par questions secrètes, la saisie des questions sera ensuite proposée.',
  profileDef_form_login_subtext: 'Ceci impactera le formulaire de saisie pour se connecter.',
  profileDef_form_identification_placeholder: 'Méthode d\'identification',
  profileDef_form_identification_email: 'Login / Mot de passe',
  profileDef_form_identification_nickname: 'Pseudonyme / Mot de passe',
  profileDef_form_identification_file: 'No de dossier',
  profileDef_form_without_identification: 'Sans identification',
  profileDef_form_identification_secretQuestions: 'Questions secrètes',
  profileDef_form_inclusion_text: 'Cochez la case suivante si vous voulez définir des questions pour inclure les participants.',
  profileDef_form_inclusion_hasInclusion: 'Inclusion',
  profileDef_form_inclusionQuestions_title: 'Gérer les questions d\'inclusion',
  profileDef_form_inclusionQuestions_add: 'Ajouter question',
  profileDef_form_inclusionQuestions_empty: 'Aucune question dans la liste',
  profileDef_form_inclusionQuestions_drawer_title_edit: 'Edition de question',
  profileDef_form_inclusionQuestions_drawer_title_create: 'Création de question',
  profileDef_form_inclusionQuestions_form_title: 'Intitulé',
  profileDef_form_inclusionQuestions_form_value: 'Valeur correcte',
  profileDef_form_inclusionQuestions_form_export_code: 'Code d\'export',
  profileDef_form_inclusionQuestions_error: 'Erreur lors de la mise à jour des questions d\'inclusion',
  profileDef_form_secretQuestions_title: 'Gérer les questions secrètes',
  profileDef_form_secretQuestions_add: 'Ajouter question',
  profileDef_form_secretQuestions_empty: 'Aucune question dans la liste',
  profileDef_form_secretQuestions_drawer_title_edit: 'Edition de question',
  profileDef_form_secretQuestions_drawer_title_create: 'Création de question',
  profileDef_form_secretQuestions_form_title: 'Intitulé',
  profileDef_form_profile_text: 'Cette section vous permet de créer des profils. Entrer le nom de votre nouveau profil, et cliquer sur save pour commencer à le configurer.',
  profileDef_form_profile_placeholder: 'Nom du profil',
  profileDef_form_profile_deleteConfirm_text: 'Êtes-vous sur de vouloir supprimer ce profil? Cette action ne peut être annulée',
  profileDef_form_profile_deleteConfirm_title: 'Suppression dùn profil',
  profileDef_loadDefinition_error: 'Erreur lors du chargement de la définition du profil',
  profileDef_postSecretQuestion_success: 'La question secrète a bien été sauvegardée',
  profileDef_postSecretQuestion_error: 'Erreur lors de la création de la question secrète',
  profileDef_deleteSecretQuestion_success: 'La question secrète a bien été supprimée',
  profileDef_deleteSecretQuestion_error: 'Erreur lors de la suppression de la question secrète',
  profileDef_patchSecretQuestion_success: 'La question secrète a bien été mise à jour',
  profileDef_patchSecretQuestion_error: 'Erreur lors de la mise à jour de la question secrète',
  profileDef_postInclusionQuestion_success: 'La question d\'inclusion a bien été sauvegardée',
  profileDef_postInclusionQuestion_error: 'Erreur lors de la suppression de la question d\'inclusion',
  profileDef_deleteInclusionQuestion_success: 'La question d\'inclusion a bien été supprimée',
  profileDef_deleteInclusionQuestion_error: 'Erreur lors de la suppression de la question d\'inclusion',
  profileDef_patchInclusionQuestion_success: 'La question d\'inclusion a bien été mise à jour',
  profileDef_patchInclusionQuestion_error: 'Erreur lors de la mise à jour de la question d\'inclusion',
  profile_creation_success: 'Profil créé avec succès',
  profile_creation_error: 'Erreur lors de la création du profil',
  profile_update_success: 'Le profil a bien été mise à jour',
  profile_update_error: 'Erreur lors de la mise à jour du profil',
  profile_delete_success: 'Le profil a été correctement supprimé',
  profile_delete_error: 'Erreur lors de la suppression du profil',
  profile_title: 'Profil',
  profile_old_password: 'Ancien mot de passe',
  profile_new_password: 'Nouveau mot de passe',
  profile_confirm_new_password: 'Confirmer le nouveau mot de passe',
  profile_update_button: 'Mettre à jour le mot de passe',
  profileDef_form_add_question: 'Nouvelle question',
  profileDef_intervenor_profile: 'Nom du profil',
  profileDef_intervenors_create_title: 'Nouvel intervenant',
  profileDef_form_inclusion_deleteConfirm_title: 'Supprimer question',
  profileDef_form_inclusion_deleteConfirm_text: 'Supprimer cette question ? Cette action ne peut pas être annulée',

  // Ajout Synakene
  profileDef_form_eligibiliy_text: "Cochez la case suivante si vous voulez définir des questions d'éligiblité pour les participants.",
};
