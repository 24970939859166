export const editorjs = {
  editorJs_buttonContent: 'Select an Image',
  editorJs_clickToTune: 'Click to tune',
  editorJs_dragToMove: 'or drag to move',
  editorJs_convertTo: 'Convert to',
  editorJs_add: 'Add',
  editorJs_text: 'Text',
  editorJs_heading: 'Heading',
  editorJs_list: 'List',
  editorJs_alert: 'Warning',
  editorJs_checklist: 'Checklist',
  editorJs_quote: 'Quote',
  editorJs_delimiter: 'Delimiter',
  editorJs_html: 'Raw HTML',
  editorJs_table: 'Table',
  editorJs_link: 'Link',
  editorJs_marker: 'Marker',
  editorJs_bold: 'Bold',
  editorJs_italic: 'Italic',
  editorJs_inlineCode: 'Inline Code',
  editorJs_title: 'Title',
  editorJs_message: 'Message',
  editorJs_addLink: 'Add a link',
  editorJs_stub: 'The block can not be displayed correctly.',
  editorJs_caption: 'Caption',
  editorJs_selectImage: 'Select an Image',
  editorJs_withBorder: 'With border',
  editorJs_stretchImage: 'Stretch image',
  editorJs_withBackground: 'With background',
  editorJs_enterCode: 'Enter a code',
  editorJs_fetchLink: 'Couldn\'t fetch the link data',
  editorJs_getLink: 'Couldn\'t get this link data, try the other one',
  editorJs_wrongFormat: 'Wrong response format from the server',
  editorJs_header: 'Header',
  editorJs_enterSomething: 'Enter something',
  editorJs_ordered: 'Ordered',
  editorJs_unOrdered: 'Unordered',
  editorJs_insertColBefore: 'Insert column before',
  editorJs_insertColAfter: 'Insert column after',
  editorJs_insertRowBefore: 'Insert row before',
  editorJs_insertRowAfter: 'Insert row after',
  editorJs_deleteRow: 'Delete row',
  editorJs_deleteCol: 'Delete column',
  editorJs_delete: 'Delete',
  editorJs_moveUp: 'Move up',
  editorJs_moveDown: 'Move down',
  editorJs_code: 'Code',
};
