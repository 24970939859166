export const navBarTrad = {
  navBar_sections_configuration: 'APP CONFIGURATION',
  navBar_sections_generalParams: 'General Params',
  navBar_sections_generalConfig: 'General Config',
  navBar_sections_multiLang: 'Multi-lang',
  navBar_sections_profileType: 'Profile type',
  navBar_sections_investigators: 'Investigators',
  navBar_sections_intervenors: 'Intervenors',
  navBar_sections_subjects: 'Subjects',
  navBar_sections_translations: 'Translations',
  navBar_sections_final_params: 'Final params',
  navBar_sections_sitesLabs: 'Sites & labs',
  navBar_sections_questionnaires: 'Questionnaires',
  navBar_sections_contents: 'Contents',
  navBar_sections_study_presentations: 'Study presentations',
  navBar_sections_consent: 'Consent',
  navBar_sections_sequence_content: 'Sequence content',
  navBar_sections_sequences: 'Sequences',
  navBar_sections_randomization: 'Randomization',
  navBar_sections_randomization_settings: 'Randomization settings',
  navBar_sections_randomization_slots: 'Randomization slots',
  navBar_sections_invitations: 'Invitations',
  navBar_sections_invitation_investigators: 'Invitation investigators',
  navBar_sections_testDeploy: 'Tests & Deployments',
  navBar_sections_testMode: 'Test Mode',
  navBar_sections_webDeploy: 'Web Deployment',
  navBar_sections_mobileDeploy: 'Mobile Deployment',
  navBar_sections_dashboards: 'Dashboards',
  navBar_sections_exports: 'Data Export',
  navBar_sections_study_impl: 'STUDY IMPLEMENTATION',
};
