export const login = {
  login_title: 'Connect',
  login_subTitle: 'Welcome to Diapason',
  login_email_label: 'Email Address',
  login_email_error_valid: 'Must be a valid email',
  login_email_error_required: 'Email is required',
  login_password_label: 'Password',
  login_password_confirm: 'Password Confirmation',
  login_password_error_required: 'Password is required',
  login_button: 'Log In',
  login_button_forgot: 'Forgot password',
  login_recoveryPassword_title: 'Password Recovery',
  login_recoveryPassword_subTitle: 'Tell us your email so we can send you a recovery link',
  login_recoveryPassword_button: 'Recovery password',
  login_resetPassword_title: 'Password Reset',
  login_resetPassword_subTitle: 'Did you not receive the email ?',
  login_resetPassword_button: 'Reset password',
  login_reset_password_success: 'If your email is known to our systems, you should quickly receive an email with instructions on how to reset your password.',
  login_reset_password_error: 'An error occurred while sending email',
  login_password_error_length: 'Password must be at least 8 characters',
  login_password_error_match: 'Passwords must match',
  login_err_reset_message: 'An error occurred',
  login_description: 'Kastafiore is a platform for creating accessible research applications without any coding skill.  It can create questionnaires, as well as, managing invitations, notifications, and translation.',
  login_description_translator: 'Here you can add, edit, and delete translations for the different components of the application.',
  login_welcome_to: 'Welcome to'
};
