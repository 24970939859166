export const translations = {
  translations_list_title: 'Traductions',
  translation_button: 'Traduire',
  total_missing_translations: 'Manquants',
  total_unvalidated_translations: 'Non validés',
  total_validated_translations: 'Valides',
  translations_total_empty: 'Aucun texte à traduire',
  translations_loadList_error: 'Erreur durant le chargement de la liste des traductions',
  translations_loadDetails_error: 'Erreur lors du chargement des détails de la traduction',
  translated_successfully: 'Traduction effectuée avec succès',
  translations_label_put_success: 'Le libellé a correctement été mis à jour',
  translations_label_put_error: 'Erreur lors de la mise à jour du libellé'
};
