export const finalParams = {
  final_params_app_name: "Nom de l'application",
  final_params_app_name_content: "Veuillez choisir un nom pour votre application de recherche. Ce nom sera affiché dans l'application.",
  final_params_app_logo: 'Logo',
  final_params_app_content: "Le logo suivant sera affiché dans l'application. Vous pouvez le modifier en cliquant sur le bouton Remplacer.",
  final_params_app_add_button_text: 'Remplacer Logo',
  final_params_app_reset_button_text: 'Réinitialiser Logo',
  final_params_app_theme: 'Thème de couleur',
  final_params_app_theme_content: 'Veuillez choisir le thème de couleur pour votre application.',
};
