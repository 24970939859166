export const deployment = {
  deployment_first_card_title: 'Déploiement Web',
  deployment_first_card_content: 'Vous pouvez accéder à la version Web de votre application via le lien ci-dessous :',
  deployment_first_card_button_text: 'Déployer',
  deployment_second_card_title: 'Déploiement Android',
  deployment_second_card_content: "Le déploiement d'Android nécessite plusieurs actions manuelles. L'administrateur de Kastafiore peut vous aider dans cette démarche. En cliquant sur ce bouton, un e-mail sera envoyé à l'administrateur de Kastafiore pour aider au déploiement d'Android.",
  deployment_second_card_button_text: "Demander à l'administrateur",
  deployment_third_card_title: 'Supprimer les données de test',
  deployment_third_card_content: "En cliquant sur ce bouton, toutes les données collectées auprès des utilisateurs seront marquées comme données de test. Cela peut vous aider à faire la distinction entre les utilisateurs réels et les utilisateurs de test après l'exportation des données.",
  deployment_third_card_button_text: 'Réinitialiser les données',
};
