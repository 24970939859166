export const randomization = {
  randomization_title: 'Randomization',
  randomization_arms: 'Randomization Arms',
  randomization_arms_create_button: 'Create randomization arms',
  randomization_config_value_CLUSTER: 'Randomization by cluster',
  randomization_config_value_INDIVIDUAL: 'Individual randomization',
  randomization_config_method_AUTO: 'Automatic',
  randomization_config_method_FILE: 'Import external file',
  randomization_stratification_title: 'Stratification',
  randomization_stratification_create_button: 'Create stratification questions',
  randomization_stratification_create_title: 'Create stratification questions',
  randomization_arms_deleteConfirm_title: 'Delete randomization',
  randomization_arms_deleteConfirm_text: 'Delete this randomization ?. This action cannot be undone.',
  randomization_stratification_deleteConfirm_text: 'Delete this stratification ?. This action cannot be undone.',
  randomization_stratification_deleteConfirm_title: 'Delete stratification',
  randomization_choice: 'Randomization choice',
  randomization_method: 'Randomization method',
  randomization_arms_title_edit: 'Edit randomization arm',
  randomization_arms_title_create: 'Create randomization arm',
  randomization_create_success: 'Randomization correctly create',
  randomization_create_error: 'Error while creating new randomization',
  randomization_description_first_title: 'Randomization',
  randomization_description_second_title: 'Stratification',
  randomization_description_third_title: 'Randomization Table',
  randomization_description_fourth_title: 'Importing Randomization File',
  randomization_description_first_content: 'In the next page, you can choose to use randomization in your study. Then, you can create multiple randomization arms.',
  randomization_description_second_content: 'In the next page, you can also create stratification question(s), which are single choice type of questions. These questions will be asked from the subjects in your application.',
  randomization_description_third_content: 'After creating randomization arms and perhaps stratification questions, a table will be generated that illustrates all possible strat-arm combinations (cells of the table). Each strat (rows of the table) has a code created by the platform, and each arm (columns of the table) has a name set by you. Each strat-arm contains the number of used slots (users included in the strat-arm) and the total number of slots for the strat-arm.',
  randomization_description_fourth_content: 'You can define the order with which the users will be assigned to strat-arms and the total number of available slots for each strat-arm by importing your randomization file. The csv file should have the following structure: code;arm (example: 1;RANDO_A).',
  randomization_slots_list_title: 'Randomization available slots list',
  randomization_slots_list_createButton: 'Add new slots',
  randomization_slots_list_upload_subtitle: 'Upload a csv file with the following structure: [...Questions code];[Randomization arm code]',
  Randomization_Reinitialization_title: 'Reinitialize randomization slots',
  Randomization_Reinitialization_text: 'Are you sure to reinitialize randomization slots ?',
  Randomization_import_running: 'Import in progress. Importing may take some time, please do not close the page.',
  randomization_slots_list_upload_description: 'You can define the order with which the users will be assigned to strat-arms and the total number of available slots for each strat-arm by importing your randomization file. The csv file should have the following structure: code;arm (example: 1;RANDO_A).',
  randomization_slots_list_upload_button: 'Upload Randomization File',
  randomization_slots_list_reset_button: 'Reset Randomization Slots',
  randomization_slots_load_error: 'Error while loading randomization slots',
  randomization_slots_created_success: 'Randomization slots correctly created',
  randomization_slots_created_error: 'Error while creating randomization slots',
  randomization_reset_slots_success: 'Randomization slots correctly reset',
  randomization_reset_slots_error: 'Error while resetting randomization slots',
  randomization_update_warning_header: 'Warning!',
  randomization_update_warning: 'If you modify your randomization arms or stratification questions, this will impact your randomization table and you should reinitialize the table and import a new randomization file.'
};
