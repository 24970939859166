export const editorjs = {
  editorJs_buttonContent: 'Sélectionnez une image',
  editorJs_clickToTune: 'Cliquez pour régler',
  editorJs_dragToMove: 'ou faites glisser pour déplacer',
  editorJs_convertTo: 'Convertir en',
  editorJs_add: 'Ajouter',
  editorJs_text: 'Texte',
  editorJs_heading: 'Titre',
  editorJs_list: 'Liste',
  editorJs_alert: 'Alerte',
  editorJs_checklist: 'Liste de contrôle',
  editorJs_quote: 'Citation',
  editorJs_delimiter: 'Délimiteur',
  editorJs_html: 'HTML brut',
  editorJs_table: 'Tableau',
  editorJs_link: 'Lien',
  editorJs_marker: 'Marqueur',
  editorJs_bold: 'Gras',
  editorJs_italic: 'Italique',
  editorJs_inlineCode: 'Code en ligne',
  editorJs_title: 'Titre',
  editorJs_message: 'Message',
  editorJs_addLink: 'Ajouter un lien',
  editorJs_stub: 'Le bloc ne peut pas être affiché correctement.',
  editorJs_caption: 'Légende',
  editorJs_selectImage: 'Sélectionner une image',
  editorJs_withBorder: 'avec bordure',
  editorJs_stretchImage: 'Étirer l\'image',
  editorJs_withBackground: 'Avec fond',
  editorJs_enterCode: 'Entrer un code',
  editorJs_fetchLink: 'Impossible de récupérer les données du lien',
  editorJs_getLink: 'Impossible d\'obtenir les données du lien, essayez un autre',
  editorJs_wrongFormat: 'Format de réponse incorrect du serveur',
  editorJs_header: 'En-tête',
  editorJs_enterSomething: 'Entrer quelque chose',
  editorJs_ordered: 'Ordonné',
  editorJs_unOrdered: 'Non ordonnée',
  editorJs_insertColBefore: 'Insérer une colonne avant',
  editorJs_insertColAfter: 'Insérer une colonne après',
  editorJs_insertRowBefore: 'Insérer une ligne avant',
  editorJs_insertRowAfter: 'Insérer une ligne après',
  editorJs_deleteRow: 'Supprimer la ligne',
  editorJs_deleteCol: 'Supprimer la colonne',
  editorJs_delete: 'Supprimer',
  editorJs_moveUp: 'Déplacer vers le haut',
  editorJs_moveDown: 'Descendre',
  editorJs_code: 'Code',
};
