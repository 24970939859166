import { Button } from '@material-ui/core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ENV } from 'src/environments/environment';
import { useSelector } from 'src/store';

function DownloadButton({ endpoint }: { endpoint: string }) {
  const aRef = useRef(null);
  const { t } = useTranslation();
  const { study } = useSelector((state) => state);

  function downloadCsvTemplate() {
    // Set the href and download attributes
    aRef.current.href = `${ENV.REACT_APP_BASE_URL}/studies/${study.data.id}/${endpoint}`;
    aRef.current.download = 'image.jpg';

    // Click the a element to trigger the download
    aRef.current.click();
  }

  return (
    <>
      <Button
        onClick={() => downloadCsvTemplate()}
        color="primary"
        variant="contained"
      >

        {t('invitation_user_download_template')}
      </Button>
      <a ref={aRef} style={{ display: 'none' }}>link</a>
    </>
  );
}

export default DownloadButton;
