export const navBarTrad = {
  navBar_sections_configuration: 'APP CONFIGURATION',
  navBar_sections_generalParams: 'Paramètre général',
  navBar_sections_generalConfig: 'Configuration générale',
  navBar_sections_multiLang: 'Multi-langue',
  navBar_sections_profileType: 'Type de profil',
  navBar_sections_investigators: 'Investigateurs',
  navBar_sections_intervenors: 'Intervenants',
  navBar_sections_subjects: 'Sujets',
  navBar_sections_translations: 'Traductions',
  navBar_sections_final_params: 'Paramètres finaux',
  navBar_sections_sitesLabs: 'Sites & labs',
  navBar_sections_questionnaires: 'Questionnaires',
  navBar_sections_contents: 'Contenus',
  navBar_sections_study_presentations: 'Présentations de l\'étude',
  navBar_sections_consent: 'Consentement',
  navBar_sections_sequence_content: 'Contenu de la séquence',
  navBar_sections_sequences: 'Séquences',
  navBar_sections_randomization: 'Randomisation',
  navBar_sections_randomization_settings: 'Paramètres de randomisation',
  navBar_sections_randomization_slots: 'Emplacements de randomisation',
  navBar_sections_invitations: 'Invitations',
  navBar_sections_invitation_investigators: 'Invitation des investigateurs',
  navBar_sections_testDeploy: 'Tests & Déploiement',
  navBar_sections_testMode: 'Mode Test',
  navBar_sections_webDeploy: 'Déploiement web',
  navBar_sections_mobileDeploy: 'Déploiement Mobile',
  navBar_sections_dashboards: 'Tableau de bord',
  navBar_sections_exports: 'Export de données',
  navBar_sections_study_impl: "Implementation de l'étude",
};
