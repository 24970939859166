import { Box, Typography, Button } from '@material-ui/core';
import { ReactElement, ReactNode } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface DiapasonPageProps {
  title?: string;
  titleActions?: ReactElement;
  children: ReactNode;
  isSection?: boolean;
  onBackClick?: () => void;
  backText?: string;
  className?: string
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined
}

const DiapasonPage = ({ title = '', titleVariant = 'h3', children, titleActions, isSection, onBackClick, backText, className = '' }: DiapasonPageProps): ReactElement => (
  <Box sx={{ overflowY: 'auto', height: '100%' }} className={`bg-white rounded-xl p-6 ${className}`}>
    {
      onBackClick && (
        <Button
          size="small"
          onClick={onBackClick}
          sx={{ background: 'none' }}
          startIcon={<ArrowBackIcon />}
        >
          {backText}
        </Button>
      )
    }
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: isSection ? 0 : 3 }}>
      <Typography
        variant={isSection ? 'subtitle2' : titleVariant}
        color="textPrimary"
      >
        {title}
      </Typography>
      {titleActions || null}
    </Box>
    <>
      {children}
    </>
  </Box>
);

export default DiapasonPage;
