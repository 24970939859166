import { CircularProgress, Box } from '@material-ui/core';
import { ReactElement } from 'react';

const DiapasonLoader = (): ReactElement => (
  <Box
    sx={{
      width: '100%',
      minHeight: '400px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <CircularProgress color="primary" />
  </Box>
);

export default DiapasonLoader;
