import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';
import LogoSvg from 'src/icons/logokastafiore.svg';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: FC<LogoProps> = () => (
  <img alt="LOGO" src={LogoSvg} />
);

export default Logo;
