import { Table, TableHead, TableCell, TableRow, Typography, TableBody } from '@material-ui/core';
import { ReactElement } from 'react';

const RandomizationArmSlotsList = ({ slots = {} }: any): ReactElement => {
  const renderSlot = (slot: any,): ReactElement => (
    <TableRow
      hover
    >
      {slot?.combination?.map((value) => (
        <TableCell>
          <Typography key={value?.label} variant="subtitle2" width={100}>
            {value?.label}
          </Typography>
        </TableCell>
      ))}
      {Object.values(slot?.randomisationArms).map((value:any) => (
        <TableCell>
          <Typography variant="subtitle2">
            {`${value.taken} users / ${value.empty + value.taken} slots`}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
  return (
    slots && (
    <Table>
      <TableHead>
        <TableRow>
          {[...slots?.header?.questions, ...slots?.header?.randomisationArms].map((value) => (
            <TableCell key={value}>
              {value}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {slots?.data?.map(renderSlot)}
      </TableBody>
    </Table>
    )
  );
};

export default RandomizationArmSlotsList;
