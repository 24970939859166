import { common } from './common.fr';
import { questionnaire } from './questionnaire.fr';
import { navBarTrad } from './navBarTrad.fr';
import { profileDef } from './profileDef.fr';
import { question } from './question.fr';
import { contents } from './contents.fr';
import { study } from './study.fr';
import { lang } from './lang.fr';
import { login } from './login.fr';
import { sites } from './sites.fr';
import { sequence } from './sequence.fr';
import { editorjs } from './editorJs.fr';
import { errorMessages } from './error.fr';
import { invitation } from './invitation.fr';
import { home } from './home.fr';
import { translations } from './translations.fr';
import { role } from './role.fr';
import { randomization } from './randomization.fr';
import { stratification } from './stratification.fr';
import { profiles } from './profiles.fr';
import { deployment } from './deployment.fr';
import { exportData } from './dataExport.fr';
import { finalParams } from './finalParams.fr';
import { InvitationUsers } from './invitationUsers.fr';
import { questionnairDependencies } from './questionnairDependencies.fr';
import { massCommunication } from './massCommunication.fr';
import { resetData } from './dataReset.fr';

export const diapasonTradFr = {
  ...common,
  ...questionnaire,
  ...navBarTrad,
  ...profileDef,
  ...question,
  ...study,
  ...lang,
  ...login,
  ...contents,
  ...login,
  ...sites,
  ...editorjs,
  ...invitation,
  ...InvitationUsers,
  ...sequence,
  ...home,
  ...translations,
  ...role,
  ...randomization,
  ...stratification,
  ...profiles,
  ...deployment,
  ...exportData,
  ...finalParams,
  ...questionnairDependencies,
  ...massCommunication,
  ...errorMessages,
  ...resetData
};
