import { LangEnum } from './common';
import { StudyType } from './study';

export interface LoginResponse {
  token: string;
}

export interface DiapasonUserType {
  id: 1,
  firstname: string,
  lastname: string,
  email: string,
  phone_number: string,
  status: UserStatusEnum,
  expiration_date: string,
  lang: LangEnum,
  is_admin: boolean,
  studies: UserStudyType[]
}

export interface UserStudyType {
  id: number,
  name: string,
  role: UserRoleEnum,
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE'
}

export type FullStudyType = StudyType & UserStudyType;

export enum UserRoleEnum {
  RESEARCHER = 'RESEARCHER',
  TRANSLATOR = 'TRANSLATOR',
  INVESTIGATOR = 'INVESTIGATOR',
  INTERVENOR = 'INTERVENOR',
  SUBJECT = 'SUBJECT',
}

export interface StudyRoleType {
  id: number,
  profile: string,
  role: UserRoleEnum
}

export interface ResetPassType {
  reset_password_token: string,
  password: string,
  password_confirmation: string,
  login: string
}

export interface UpdatePassType {
  login: string,
  old_password: string,
  new_password: string
}

export interface UpdateLang {
  id: number,
  lang: string
}
