import { Link, Typography } from '@material-ui/core';
import { memo, ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Logo from './Logo';

interface DiapasonLogoProps {
  textColor?: string
}

const DiapasonLogo = ({ textColor = 'text-dark' }: DiapasonLogoProps): ReactElement => (
  <Link
    to="/home-page"
    sx={{
      display: 'flex',
      alignItems: 'center'
    }}
    component={RouterLink}
  >
    <Logo
      sx={{
        height: 40,
        width: 40
      }}
    />
    <Typography
      className={textColor}
      variant="h5"
      sx={{
        ml: 2
      }}
    >
      KASTAFIORE
    </Typography>
  </Link>
);

export default memo(DiapasonLogo);
