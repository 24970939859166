import { ReactElement } from 'react';
import { useSelector } from 'src/store';
import DiapasonLoader from '../atoms/DiapasonLoader';

interface StudyGuardProps {
  children: ReactElement;
}

const StudyGuard = ({ children }: StudyGuardProps): ReactElement => {
  const { study: { isStudyReady } } = useSelector((state) => state);

  if (!isStudyReady) {
    return <DiapasonLoader />;
  }

  return children;
};

export default StudyGuard;
