export const contents = {
  contents_title: 'Contents page',
  contents_list_title: 'Contents list',
  contents_list_createButton: 'Create new content',
  contents_list_deleteConfirm_title: 'Delete content',
  contents_list_deleteConfirm_text: 'Are you sure to delete this content? This action can\'t be undone',
  contents_form_title: 'Create content',
  contents_form_subtitle: 'Enter content informations',
  contents_form_submit: 'Save content',
  contents_search_placeholder: 'Search by label',
  contents_details_backToList: 'Go back to contents list',
  contents_loadList_error: 'Contents list loading has failed',
  contents_delete_success: 'The content has been successfully deleted',
  contents_delete_error: 'Content deletion has failed',
  contents_create_success: 'The content has been successfully created',
  contents_create_error: 'Content creation has failed',
  contents_details_load_error: 'Content loading has failed',
  contents_details_put_success: 'The content has been successfully updated',
  contents_details_put_error: 'The content update has failed',
  contents_details_uploadFile_success: 'File has been saved',
  contents_details_uploadFile_error: 'Error while saving file',
  content_type_CONSENT: 'Consent',
  content_type_HOMESTUDY: 'Study home',
  content_type_ONBOARDINGSTUDY: 'Study on boarding',
  content_type_QUESTIONNAIRE: 'Sequence content',
  content_type_SEQBEGIN: 'Sequence begin',
  content_type_SEQEND: 'Sequence end',
  content_type_INELIGIBLEINCLUSION: 'Ineligible page',
  content_type_DETAILEDHOMESTUDY: 'Detail Page',
  content_type_LOGGUEDHOMESTUDY: 'Landing page',
  content_description_first_title: 'Study Presentation',
  content_description_second_title: 'Consent',
  content_description_third_title: 'Sequence Content',
  content_description_first_content: 'In the next pages, you can present your study to the users of your application. You can set your text and customize it with the available typography. You can also add images, tables, and videos (such as YouTube video link) to your study presentation.',
  content_description_second_content: 'Participants in a research study are usually required to give their consent. In the next pages you can set the content of your consents and assign them to a user profile (subject, investigator, intervenor).',
  content_description_third_content: 'In the next pages, you can also set the content(s) that can be displayed before or after your questionnaires, or at different steps (sequences) of your study.',
};
