export const study = {
  study_config_multilang_title: 'Translation Languages',
  study_config_multilang_text: 'Please select the study additional languages',
  study_config_lang_title: 'Study Lang',
  study_config_lang_text: 'Please select the study main language',
  study_config_multilang_creation_error: 'Study lang creation has failed',
  study_config_multilang_creation_success: 'Study lang creation has succeeded',
  study_config_multilang_delete_error: 'Study lang deletion has failed',
  study_config_multilang_delete_success: 'Study lang deletion has succeeded',
  study_config_multilang_load_error: 'Study lang loading has failed',
  study_config_lang_load_error: 'Lang loading has failed',
};
