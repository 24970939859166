export const common = {
  common_cancel: 'Cancel',
  common_ok: 'Ok',
  common_createdAt: 'Created at',
  common_createdBy: 'Created by',
  common_updated: 'Updated',
  common_updatedAt: 'Updated at',
  common_updatedBy: 'Updated by',
  common_status: 'Status',
  common_profile: 'Profile',
  common_title: 'Title',
  common_description: 'Description',
  common_integrity: 'Integrity',
  common_type: 'Type',
  common_days_number: 'Offset delay (in days)',
  common_keywords: 'Keywords',
  common_points: 'Points',
  common_landscapes: 'Landscapes',
  common_variable_name: 'Variable name',
  common_avg_amount_time_to_answer: 'Average time to answer',
  common_avg_amount_time_to_read: 'Average time to read',
  common_mandatory: 'Mandatory',
  common_complete: 'Complete',
  common_dependency: 'Dependency',
  common_version: 'Version',
  common_answers: 'Answers',
  common_loading: 'Loading',
  common_inclusion: 'Inclusion',
  common_consent: 'Consent',
  common_edit: 'Edit',
  common_save: 'Save',
  common_true: 'True',
  common_false: 'False',
  common_yes: 'Yes',
  common_no: 'No',
  common_format: 'Format',
  common_min: 'Min',
  common_max: 'Max',
  common_prefix: 'Prefix (Optional)',
  common_suffix: 'Suffix (Optional)',
  common_separator: 'Separator',
  common_step: 'Step',
  common_label: 'Label',
  common_next: 'Next answer',
  common_next_page: 'Next',
  common_asset: 'Asset',
  common_value: 'Value',
  common_direction: 'Direction',
  common_quantity: 'Quantity',
  common_constraint: 'Constraint',
  common_unit: 'Unit',
  common_date: 'Date',
  common_investigator: 'Investigator',
  common_intervenor: 'Intervenor',
  common_subject: 'Subject',
  common_questionnaire: 'Questionnaire',
  common_content: 'Content',
  common_minutes: 'Minutes',
  common_required: 'Required',
  common_reset: 'Reinitialize',
  common_actions: 'Actions',
  common_average_read_time: 'Average reading time',
  common_delete: 'Delete',
  common_back: 'Back',
  common_dot: 'Dot',
  common_comma: 'Comma',
  common_export_code: 'Export code',
  common_study: 'Study',
  common_question: 'Question',
  common_answers_number: 'Answers number',
  common_delete_success: 'Item successfully deleted',
  common_validated: 'Validated',
  common_days: 'Days',
  common_empty: 'To be completed',
  common_start: 'Start',
  common_start_translator: 'Start translation',
  common_search_placeholder: 'Type here to search',
  common_copy_link: 'Copy Link',
  optional: 'Optional',

  // Ajout Synakene
  common_eligibility: 'Eligibility',
};
